<!--
 * @Description: 项目模板
 * @version: 
 * @Author: Tom
 * @Date: 2024-11-21 08:41:36
 * @LastEditors: Tom
 * @LastEditTime: 2024-12-09 08:41:36
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'1100px'"
    :top="'5%'"
    center
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="closeDialog"
  >
    <template v-slot:title>
      <div class="tasknameslotTitle">
        <p class="title_p overflowEllipsis">
          <span>{{ title }}</span>
        </p>
        <div class="tasktitlebtn">
          <div>
            <!--   创建项目模板 -->
            <el-button type="text" @click="createProjectTemplate()">
              <div class="svgbtn">
                <svg-icon icon-class="create" class="icon"></svg-icon>
                <p>{{ $t("ProjectManagement.createProjectTemplate") }}</p>
              </div>
            </el-button>
          </div>
          <div class="lastdiv"></div>
        </div>
      </div>
    </template>
    <div class="GlossariesDialog" style="height: 465px">
      <!-- 筛选 -->
      <div class="contentCenter_tags screenStyle" style="margin-left: 0px">
        <p>{{ $t("CommonName.screen") }}</p>
        <el-tag
          v-for="tag in tags"
          :key="tag.name"
          closable
          :type="tag.type"
          @close="handleClose(tag)"
        >
          <p
            class="screenp"
            :id="'tag' + tag.id"
            @mouseover="onShowNameTipsMouseenter(tag)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="tag.name"
              placement="top-start"
              :disabled="!tag.showdropdown"
            >
              <span>{{ tag.name }}</span>
            </el-tooltip>
          </p>
        </el-tag>
      </div>
      <div class="glo_bottom">
        <div
          class="tableClass borderTableStyle"
          :class="tableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            ref="tableRef"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; overflow-y: auto; border-right: 1px solid"
            max-height="356px"
            @sort-change="sortChange"
            :default-sort="defaultSort"
            border
          >
            <!-- 名称 -->
            <el-table-column prop="template_name" show-overflow-tooltip>
              <template slot="header">
                <div class="tableheaderdiv">
                  <p class="namep">
                    {{ $t("ProjectManagement.projectTemplateName") }}
                  </p>
                  <div class="icondiv">
                    <el-popover
                      placement="bottom-end"
                      width="430"
                      v-model="visible"
                      :visible-arrow="false"
                    >
                      <div class="popoverDiv">
                        <el-input
                          v-model="popoverInput"
                          :placeholder="$t('CommonName.search')"
                          maxlength="100"
                          @keyup.enter.native="searchdetermineClick"
                        ></el-input>
                        <el-button
                          type="primary"
                          class="setpadding16"
                          @click="searchdetermineClick"
                          >{{ $t("ProjectManagement.query") }}
                        </el-button>
                      </div>
                      <svg-icon
                        icon-class="Filter"
                        class="iconsize"
                        slot="reference"
                      ></svg-icon>
                    </el-popover>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  class="menuscopediv overflowEllipsis"
                  style="cursor: pointer"
                  @click="editMethod(scope.row)"
                >
                  {{ scope.row.template_name }}
                </div>
              </template>
            </el-table-column>
            <!-- 已关联项目数量 -->
            <el-table-column
              prop="project_count"
              width="150"
              :label="$t('ProjectManagement.AssociatedProject')"
            >
              <template slot-scope="scope">
                <div
                  v-if="scope.row.project_count > 0"
                  class="menuscopediv overflowEllipsis"
                  style="cursor: pointer"
                  @click="checkMethod(scope.row)"
                >
                  <p>{{ scope.row.project_count }}</p>
                </div>
                <div v-else>{{ scope.row.project_count }}</div>
              </template>
            </el-table-column>
            <!-- 创建人 -->
            <!-- <el-table-column
              prop="create_by"
              :label="$t('ProjectManagement.Createdby')"
              width="110"
            >
            </el-table-column> -->
            <!-- 创建时间 -->
            <el-table-column
              prop="create_time"
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              width="180"
              :label="$t('MemberDetails.createTime')"
            >
            </el-table-column>
            <!-- 操作 -->
            <el-table-column width="80">
              <template slot-scope="scope">
                <div class="tableBtnClass">
                  <!-- 删除 -->
                  <el-button
                    v-if="scope.row.project_count == 0"
                    class="btn1"
                    type="text"
                    @click="deleteMethod(scope.row)"
                  >
                    <svg-icon icon-class="delete" class="icon"></svg-icon>
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 字幕设置 -->
    <ProjectCaptionSettings
      v-if="showCaptionSettings"
      :showdialog="showCaptionSettings"
      :projectTemplateInfo="projectTemplateInfo"
      :CaptionMode="CaptionMode"
      @clickCaptionSettings="clickCaptionSettings"
    ></ProjectCaptionSettings>
    <el-dialog
      :title="NumTitle"
      :visible.sync="dialogNumVisible"
      :width="'1100px'"
      :top="'5%'"
      center
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="closeNumDialog"
    >
      <template v-slot:title>
        <div class="tasknameslotTitle">
          <p class="title_p overflowEllipsis">
            <el-tooltip
              class="item"
              effect="dark"
              :content="NumTitle"
              placement="top-start"
            >
              <span>{{ NumTitle }}</span>
            </el-tooltip>
          </p>
        </div>
      </template>
      <div class="GlossariesDialog" style="height: 365px">
        <div class="glo_bottom">
          <div
            class="tableClass borderTableStyle"
            :class="ProjectTableData.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="ProjectTableData"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="353px"
              border
            >
              <!-- 名称 -->
              <el-table-column prop="project_name">
                <template slot="header">
                  <div class="tableheaderdiv">
                    <p class="namep">
                      {{ $t("ProjectManagement.projectName") }}
                    </p>
                  </div>
                </template>
                <template slot-scope="scope">
                  <p v-text="scope.row.project_name"></p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { get_project_list } from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import ProjectCaptionSettings from "./ProjectCaptionSettings.vue";
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    current_project_template_id: {
      type: String,
      default: "",
    },
  },
  components: {
    ProjectCaptionSettings,
  },
  data() {
    return {
      dialogVisible: false,
      title: "",
      tableData: [],
      dubbingInterval: null,
      popoverInput: "",
      visible: false,
      tags: [],
      older: "create_time",
      sort: "desc",
      pageSize: 20,
      currentPage: 1,
      defaultSort: { prop: "create_time", order: "descending" },
      showCaptionSettings: false,
      projectTemplateInfo: {},
      CaptionMode: 0,
      dialogNumVisible: false,
      NumTitle: "",
      ProjectTableData: [],
    };
  },
  created() {},
  mounted() {
    console.log("this.showDialog", this.showDialog);
    this.dialogVisible = this.showDialog;
    this.title = this.$t("ProjectManagement.projectTemplateManage");
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_project_template_list().then((res) => {
      this.$nextTick(() => {
        let findIndex = this.tableData.findIndex(
          (item) => item.project_template_id == this.current_project_template_id
        );
        const table = this.$refs.tableRef;
        // console.log("findIndex", findIndex);
        // 计算目标行的高度，并滚动到该位置
        const row = table.$el.querySelectorAll(".el-table__row")[findIndex];
        const scrollTop = row.offsetTop;
        table.$el.querySelector(".el-table__body-wrapper").scrollTop =
          scrollTop;
        hideLoading();
        if (this.current_project_template_id != "0") {
          let arr = {
            project_template_id: this.current_project_template_id,
          };
          this.editMethod(arr);
        }
      });
    });
  },
  methods: {
    createProjectTemplate() {
      let data = {
        action: "get_project_template_info",
        project_template_id: "-1",
      };
      get_project_list(data).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.projectTemplateInfo = res.info;
        this.showCaptionSettings = true;
        this.CaptionMode = 0;
      });
    },
    /**
     *
     * @param {* 字幕设置  弹窗} val
     */
    clickCaptionSettings(val, data = 0) {
      this.showCaptionSettings = val;
      if (data == 1) {
        showLoading(this.$t("CommonName.loadingmessage"));
        this.get_project_template_list().then((res) => {
          hideLoading();
        });
      }
    },
    /**
     * 排序
     * @param {*} column
     */
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("CommonName.loadingmessage"));
      if (column.order == "ascending") {
        this.older = column.prop;
        this.sort = "asc";
      } else {
        this.older = column.prop;
        this.sort = "desc";
      }
      this.currentPage = 1;
      this.get_project_template_list();
    },
    // 修改
    editMethod(row) {
      let data = {
        action: "get_project_template_info",
        project_template_id: row.project_template_id,
      };
      get_project_list(data).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.projectTemplateInfo = res.info;
        this.showCaptionSettings = true;
        this.CaptionMode = 1;
      });
    },
    //删除
    deleteMethod(row) {
      this.$confirm(
        this.$t("CommonName.deleteMessage6"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        let data = {
          action: "delete_project_template",
          project_template_id: row.project_template_id,
        };
        get_project_list(data).then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          this.get_project_template_list().then((res) => {
            hideLoading();
          });
        });
      });
    },
    // 查看已关联项目
    checkMethod(row) {
      this.dialogNumVisible = true;
      this.NumTitle =
        row.template_name +
        " " +
        this.$t("ProjectManagement.AssociatedProject");
      let data = {
        action: "get_template_projects",
        project_template_id: row.project_template_id,
      };
      get_project_list(data).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.ProjectTableData = res.list;
      });
    },
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.currentPage = 1;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.get_project_template_list().then((res) => {
        hideLoading();
      });
    },
    /**
     * 表格表头 名称 筛选 按钮
     */
    searchdetermineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("ProjectManagement.projectTemplateName"),
          this.popoverInput,
          this.tags,
          "template_name",
          this.popoverInput
        );
        this.currentPage = 1;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      this.get_project_template_list().then((res) => {
        hideLoading();
      });
      this.visible = false;
      this.popoverInput = "";
    },
    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     *
     * @param data
     */
    get_project_template_list() {
      let data = { action: "get_project_template_list" };
      if (this.older != "" && this.sort != "") {
        data["older"] = this.older;
        data["sort"] = this.sort;
      }
      data["pageSize"] = this.pageSize;
      data["currentPage"] = this.currentPage;
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      return get_project_list(data).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.tableData = res.list;
        // this.tableData = this.setTableData(res.list);
        return res;
      });
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
      this.get_project_template_list();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.get_project_template_list();
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.$emit("closeDialog", this.dialogVisible);
      this.dialogVisible = false;
    },
    /**
     * 关闭弹窗
     */
    closeNumDialog() {
      this.dialogNumVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/dialogStyle.scss";
.GlossariesDialog {
  margin: 24px;

  .glo_top {
    margin-top: 22px;
    .el-button + .el-button {
      margin-left: 18px;
    }
  }
  .glo_bottom {
    margin-top: 22px;
    height: 273px;

    .tableClass {
      width: 100%;
      .menuscopediv {
        color: #46adff;
        font-size: 14px;
      }
    }
  }
}
.slottitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-left: 24px;
  }

  .el-button {
    margin-right: 75px;
  }
}

.tasknameslotTitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title_p {
    width: 240px;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    color: rgb(255, 255, 255);
  }
  .title_p1 {
    width: 400px;
  }
  p {
    margin-left: 24px;
  }

  .tasktitlebtn {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .svgbtn {
      margin-right: 14px;
    }
  }

  .lastdiv {
    width: 1px;
    height: 12px;
    background-color: #757784;
    margin: 0 24px 0 10px;
  }
}
.tableBtnClass {
  .btn1 {
    font-size: 17px;
  }
}
</style>
