<!--
 * @Description: 项目信息
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-15 08:35:50
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-20 11:08:52
-->
<template>
  <div class="taskDialog">
    <div class="dialogcontent_header">
      <!-- 基础信息 -->
      <p class="headerp">{{ $t("ProjectManagement.Basicinformation") }}</p>
      <div style="height: 42px">
        <el-button
          type="text"
          @click="showinerdialog(1)"
          v-if="dataInfo.create_id == user_id"
        >
          <div class="buttondiv">
            <svg-icon icon-class="Edit" class="icon"></svg-icon>
            <!-- <p>编辑项目</p> -->
            <p>{{ $t("ProjectManagement.EditItem") }}</p>
          </div>
        </el-button>
        <el-button
          type="text"
          @click="showinerdialog(2)"
          v-if="
            this.dataInfo.status == 10 &&
            dataInfo.create_id == user_id &&
            commandRow.project_type == '0'
          "
        >
          <div class="buttondiv">
            <svg-icon icon-class="CloseProject" class="icon"></svg-icon>
            <!-- <p>删除项目</p> -->
            <p>{{ $t("ProjectDetails.CloseProject") }}</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="dialogcontent_body">
      <div class="body">
        <!-- 项目名称 -->
        <div class="left lefts">{{ $t("ProjectManagement.projectName") }}</div>
        <div class="right rights">{{ dataInfo.project_name }}</div>
      </div>
      <div class="body" v-if="commandRow.project_type == '0'">
        <!-- 状态 -->
        <div class="left">{{ $t("CommonName.status") }}</div>
        <div class="right">
          {{
            dataInfo.status == "10"
              ? $t("ProjectManagement.haveinhand")
              : $t("ProjectManagement.Delivered")
          }}
        </div>
      </div>
      <div class="body" v-if="commandRow.project_type == '0'">
        <!-- Media Length -->
        <div class="left">{{ $t("ProjectManagement.MediaLength") }}</div>
        <div class="right right1">{{ dataInfo.total_duration }}</div>
        <!-- CREATED -->
        <div class="left">{{ $t("ProjectManagement.CREATED") }}</div>
        <div class="right">{{ dataInfo.create_time }}</div>
      </div>
      <div class="body" v-if="commandRow.project_type == '0'">
        <!-- Time Code Mode -->
        <div class="left">{{ $t("ProjectManagement.ProjectSettings") }}</div>
        <div class="right rights">
          {{ dataInfo.dig == "2" ? "00:00:00.00" : "00:00:00.000" }}
          {{ dataInfo.othersettings }}
        </div>
      </div>
      <div class="body" v-if="commandRow.project_type == '0'">
        <!-- Time Code Mode -->
        <div class="left">{{ $t("ProjectManagement.projectTemplate") }}</div>
        <div class="right right3">
          {{ dataInfo.project_template_name }}
        </div>
      </div>
      <div class="body" v-if="commandRow.project_type == '0'">
        <!-- Time Code Mode -->
        <div class="left">{{ $t("ProjectManagement.Shares") }}</div>
        <div class="right right3">
          {{ dataInfo.share_list }}
        </div>
      </div>
      <div class="body body1">
        <!-- Comments -->
        <div class="left lefts">{{ $t("TMManagement.Comments") }}</div>
        <div class="right rights">{{ dataInfo.comments }}</div>
      </div>
    </div>
    <el-dialog
      width="888px"
      :title="innerVisibleTitle"
      :visible.sync="innerVisible"
      @close="CloseinnerVisible"
      append-to-body
      top="10%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
    >
      <!--      <el-dialog-->
      <!--        width="888px"-->
      <!--        :title="innerVisibleTitle"-->
      <!--        :visible.sync="innerVisible"-->
      <!--        @close="CloseinnerVisible"-->
      <!--        append-to-body-->
      <!--        top="10%"-->
      <!--        center-->
      <!--        :close-on-click-modal="false"-->
      <!--        :close-on-press-escape="false"-->
      <!--        :destroy-on-close="true"-->
      <!--      ></el-dialog>-->
      <el-form
        ref="form"
        :model="form"
        label-position="top"
        class="formStyleClass dialogformStyleClass"
        @keydown.enter.prevent
      >
        <!-- 项目名称 -->
        <el-form-item :label="$t('ProjectManagement.projectName')">
          <el-input
            v-model="form.project_name"
            :maxlength="100"
            :placeholder="$t('ProjectManagement.projectNameplaceholder')"
          ></el-input>
        </el-form-item>
        <!-- 基础设置 -->
        <el-form-item
          :label="$t('ProjectManagement.BasicSetting')"
          v-if="commandRow.project_type == '0'"
        >
          <el-checkbox-group v-model="form.type_mode">
            <div class="checkboxClass">
              <div>
                <el-checkbox :label="1" name="type" border
                  >{{ $t("ProjectManagement.AllowTimeCodesOverlap") }}
                </el-checkbox>
                <el-checkbox
                  :label="3"
                  name="type"
                  border
                  style="margin-top: 6px"
                  >{{ $t("ProjectManagement.MachineTranslation") }}
                </el-checkbox>
              </div>
              <div style="margin-left: 40px">
                <el-checkbox :label="2" name="type" border
                  >{{
                    $t(
                      "ProjectManagement.Allowreviewertoeditoriginalsubtitlefile"
                    )
                  }}
                </el-checkbox>
                <el-checkbox
                  :label="4"
                  name="type"
                  border
                  style="margin-top: 6px"
                  >{{ $t("ProjectManagement.Allowtranslationtomodify") }}
                </el-checkbox>
              </div>
            </div>
            <div class="checkboxClass">
              <el-checkbox
                :label="11"
                name="type"
                border
                style="margin-top: 6px; width: 840px"
                >{{ $t("ProjectManagement.Subtitling") }}
              </el-checkbox>
            </div>
            <div class="checkboxClass">
              <el-checkbox
                :label="5"
                name="type"
                border
                style="margin-top: 6px; width: 840px"
                >{{ $t("ProjectManagement.tai") }}
              </el-checkbox>
            </div>
            <div class="checkboxClass">
              <el-checkbox
                :label="10"
                name="type"
                border
                style="margin-top: 6px; width: 840px"
                >{{ $t("ProjectManagement.captionsMove") }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          :label="$t('ProjectManagement.dubSetting')"
          v-if="commandRow.project_type == '1'"
        >
          <div class="checkboxClass">
            <div>
              <el-checkbox v-model="auto_clone" border
                >{{ $t("ProjectManagement.autoClone") }}
              </el-checkbox>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('ExternalCreateTask.TimeCodeMode')"
          v-if="info.role == 3 && commandRow.project_type == '0'"
        >
          <div class="radioClass">
            <el-radio-group v-model="form.dig">
              <el-radio border label="3">00:00:00.000</el-radio>
              <el-radio border label="2">00:00:00.00</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <!--          项目名称 顺序-->
        <el-form-item :label="$t('ExternalCreateTask.sortText')">
          <div class="radioClass">
            <el-radio-group v-model="form.sort_mode">
              <el-radio border label="0"
                >{{ $t("ExternalCreateTask.sortText1") }}
              </el-radio>
              <el-radio border label="1"
                >{{ $t("ExternalCreateTask.sortText2") }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <!--          字幕显示方式-->
        <el-form-item :label="$t('ExternalCreateTask.subShowText')">
          <div class="radioClass">
            <el-radio-group v-model="form.captions_cross">
              <el-radio border label="0"
                >{{ $t("ExternalCreateTask.subShowText1") }}
              </el-radio>
              <el-radio border label="1"
                >{{ $t("ExternalCreateTask.subShowText2") }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <!-- 项目模板 -->
        <el-form-item
          :label="$t('ProjectManagement.projectTemplate')"
          v-if="commandRow.project_type == '0'"
        >
          <el-select
            v-model="form.project_template_id"
            :placeholder="$t('CommonName.Pleaseselect')"
            filterable
            clearable
            :class="'templateSelect'"
          >
            <el-option
              v-for="item in tableData1"
              :key="item.project_template_id"
              :label="item.template_name"
              :value="item.project_template_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item :label="$t('CommonName.remarks')">
          <el-input
            class="textAreaClassHeight"
            type="textarea"
            v-model="form.comments"
            :maxlength="300"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
      >
        <el-button type="info" @click="CancelClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button type="primary" @click="SureClick" class="setpadding23"
          >{{ $t("CommonName.Sure") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  get_project_info,
  update_project_info,
  close_project,
  update_media_project,
  get_project_list,
} from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";

export default {
  props: {
    project_id: {
      type: String,
      default: "",
    },
    user_id: {
      type: String,
      default: "",
    },
    commandRow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    /**
     * 获取 详情 数据
     */
    get_project_info() {
      return get_project_info({
        action: "get_project_info",
        project_id: this.project_id,
      }).then((res) => {
        if (res) {
          let str = [];
          if (res.info.th_lang_mode === "1") {
            res.info.type_mode.push(5);
          }
          if (res.info.captions_move === "1") {
            res.info.type_mode.push(10);
          }
          if (res.info.smearing_mode === "1") {
            res.info.type_mode.push(11);
          }
          res.info.sort_mode === "0"
            ? res.info.type_mode.push(6)
            : res.info.type_mode.push(7);
          res.info.captions_cross === "1"
            ? res.info.type_mode.push(9)
            : res.info.type_mode.push(8);
          if (res.info.type_mode.length === 0) {
            res.info.othersettings = "";
          } else {
            res.info.type_mode.forEach((i) => {
              if (i === 1) {
                str.push(this.$t("ProjectManagement.AllowTimeCodesOverlap"));
              }
              if (i === 2) {
                str.push(
                  this.$t(
                    "ProjectManagement.Allowreviewertoeditoriginalsubtitlefile"
                  )
                );
              }
              if (i === 3) {
                str.push(this.$t("ProjectManagement.MachineTranslation"));
              }
              if (i === 4) {
                str.push(this.$t("ProjectManagement.Allowtranslationtomodify"));
              }
              if (i === 5) {
                str.push(this.$t("ProjectManagement.tai"));
              }
              if (i === 6) {
                str.push(this.$t("ExternalCreateTask.sortText1"));
              }
              if (i === 7) {
                str.push(this.$t("ExternalCreateTask.sortText2"));
              }
              if (i === 8) {
                str.push(this.$t("ExternalCreateTask.subShowText1"));
              }
              if (i === 9) {
                str.push(this.$t("ExternalCreateTask.subShowText2"));
              }
            });

            res.info.othersettings = ", " + str.join(", ");
          }
          this.auto_clone = res.info.auto_clone == "1" ? true : false;
          this.dataInfo = res.info;
          console.log(this.form);
          hideLoading();
        }
      });
    },
    /**
     *
     * @param {* 关闭 内部弹窗} data
     */
    CloseinnerVisible() {
      this.innerVisible = false;
    },
    /**
     *
     * @param {* 关闭 内部弹窗} data
     */
    CancelClick() {
      this.innerVisible = false;
    },
    /**
     *
     * @param {*确定 按钮}
     */
    SureClick() {
      if (!this.form.project_name.trim()) {
        this.$messages("warning", this.$t("CommonName.warningmessage3"));
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      if (this.commandRow.project_type == "0") {
        update_project_info({
          action: "update_project_info",
          project_id: this.project_id,
          project_name: this.form.project_name.trim(),
          type_mode: this.form.type_mode, //1是否允许时间轴交叉，2是否允许校对流程修改原文及时间轴，3MT，4是否允许翻译流程修改原文及时间轴
          dig: this.form.dig,
          sort_mode: this.form.sort_mode,
          captions_cross: this.form.captions_cross,
          comments: this.form.comments,
          th_lang_mode: this.form.type_mode.includes(5) ? "1" : "0",
          captions_move: this.form.type_mode.includes(10) ? "1" : "0",
          smearing_mode: this.form.type_mode.includes(11) ? "1" : "0",
          project_template_id: this.form.project_template_id,
        }).then((res) => {
          if (res) {
            this.get_project_info().then(() => {
              // console.log(res);
              this.$messages(
                "success",
                this.$t("ProjectManagement.successmessage2")
              );
              this.innerVisible = false;
              this.$emit("proInfohandleClick");
            });
          }
        });
      } else {
        update_media_project({
          action: "update_media_project",
          project_name: this.form.project_name.trim(),
          project_id: this.project_id,
          comments: this.form.comments,
          sort_mode: this.form.sort_mode,
          auto_clone: this.auto_clone ? 1 : 0,
        }).then((res) => {
          if (res) {
            this.get_project_info().then(() => {
              // console.log(res);
              this.$messages(
                "success",
                this.$t("ProjectManagement.successmessage2")
              );
              this.innerVisible = false;
              this.$emit("proInfohandleClick");
            });
          }
        });
      }
    },
    /**
     *
     * @param {* 点击 弹窗显示}data
     */
    showinerdialog(data) {
      if (data == 1) {
        this.innerVisibleTitle = this.$t("ProjectDetails.buttonList.name4");
        this.form = this.dataInfo;
        showLoading(this.$t("CommonName.loadingmessage"));
        get_project_list({ action: "get_project_template_list" }).then(
          (res) => {
            hideLoading();
            if (!res) {
              return;
            }
            this.tableData1 = res.list;
            this.innerVisible = true;
          }
        );
      } else {
        this.$confirm(
          this.$t("ProjectManagement.warningmessage3"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
            cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        ).then(() => {
          showLoading(this.$t("CommonName.loadingmessage"));
          close_project({
            action: "close_project",
            project_id: this.project_id,
          }).then((res) => {
            if (!res) {
              return;
            }
            this.get_project_info().then(() => {
              // console.log(res);
              this.$messages(
                "success",
                this.$t("ProjectManagement.successmessage3")
              );
              this.innerVisible = false;
            });
          });
        });
      }
    },
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_project_info();
    this.info = JSON.parse(sessionStorage.getItem("info"));
  },
  data() {
    return {
      info: {},
      dataInfo: {},
      innerVisible: false,
      innerVisibleTitle: "",
      form: {
        project_name: "",
        type_mode: [],
        dig: "3",
        sort_mode: "0",
        captions_cross: "0",
        comments: "",
        project_template_id: "",
        tableData1: [],
      },
      auto_clone: false,
    };
  },
  created() {},
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/dialogStyle.scss";

.dialogformStyleClass {
  padding: 0 24px 24px 24px;
  .templateSelect {
    width: 100% !important;
  }
}

.taskDialog {
  margin: 14px 24px 0 24px;
  padding-bottom: 102px;

  .dialogcontent_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerp {
      color: #adb0bc;
      font-size: 14px;
    }

    .buttondiv {
      display: flex;
      align-items: center;
      margin-left: 31px;

      p {
        font-size: 14px;
        margin-left: 6px;
      }

      .icon {
        font-size: 16px;
      }
    }
  }

  .dialogcontent_body {
    .body {
      width: 841px;
      border: 1px solid;
      border-bottom: none;
      border-color: #515464;
      background-color: #393c4e;
      display: flex;
      font-size: 14px;
      align-items: center;
      height: auto;
      color: #ffffff;

      .left {
        padding-left: 12px;
        width: 176px;
        color: #adb0bc;
        // height: auto;
        background-color: #454758;
        border-right: 1px solid;
        border-color: #515464;
      }

      .lefts {
        line-height: 95px !important;
        height: 95px !important;
      }

      .right {
        padding-left: 12px;
      }

      .rights {
        box-sizing: border-box;
        word-break: keep-all;
        overflow-wrap: break-word;
        width: calc(100% - 176px);
        line-height: normal;
      }

      .right1 {
        width: 245px;
      }

      .right3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        width: 623px;
        height: 40px;
      }
    }

    .body1 {
      border-bottom: 1px solid;
      border-color: #515464;
    }
  }
}
</style>
