<!--
 * @Description: 开通企业 VIP
 * @version: 
 * @Author: Carful
 * @Date: 2022-12-08 16:55:45
 * @LastEditors: Carful
 * @LastEditTime: 2023-03-08 17:09:19
-->
<template>
  <div class="content_center">
    <div class="content_center_all">
      <div class="content_center_top">
        <div class="content_center_left">
          <div class="currencyDiv">
            <div class="currencyDiv_left currencyDiv_left1"></div>
            <!--            个人版-->
            <div
              style="background-color: #454758"
              class="currencyDiv_center currencyDivcur currencyDiv_center1"
            >
              {{ $t("PurchaseServices.Personal") }}
            </div>
            <!--            企业 VIP 版-->
            <div class="currencyDiv_right currencyDiv_right1 currencyDivcur">
              {{ $t("PurchaseServices.EnterpriseVIPEdition") }}
            </div>
          </div>
          <div class="currencyDiv">
            <div class="currencyDiv_left currencyDiv_top_left"></div>
            <div class="currencyDiv_center currencyDiv_top_center">
              {{ $t("PurchaseServices.Noannualfee") }}
            </div>
            <div class="currencyDiv_right currencyDiv_top_right">
              <p>
                299<span>/{{ $t("PurchaseServices.year") }}</span>
              </p>
              <!--            立即开通  -->
              <el-button
                type="primary"
                @click="
                  gobuy({
                    Pricep: '299',
                    timep: '12',
                    titlep: $t('PurchaseServices.EnterpriseVIPEdition'),
                  })
                "
                >{{ $t("PurchaseServices.activatenow") }}</el-button
              >
            </div>
          </div>
          <div class="currencyDiv" v-if="false">
            <!--            购买时长卡折扣-->
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.durationcard") }}</p>
            </div>
            <div class="currencyDiv_center">
              {{ $t("PurchaseServices.Nodiscount") }}
            </div>
            <div class="currencyDiv_right">
              9{{ $t("PurchaseServices.break") }}
            </div>
          </div>
          <!--          字幕下载-->
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.subtitledownload") }}</p>
            </div>
            <div class="currencyDiv_center">
              {{ $t("PurchaseServices.unlimited") }}
            </div>
            <div class="currencyDiv_right">
              {{ $t("PurchaseServices.unlimited") }}
            </div>
          </div>
          <!--          音频下载-->
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.audiodownload") }}</p>
            </div>
            <div class="currencyDiv_center">
              {{ $t("PurchaseServices.unlimited") }}
            </div>
            <div class="currencyDiv_right">
              {{ $t("PurchaseServices.unlimited") }}
            </div>
          </div>
          <!--          视频下载-->
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.videoDownload") }}</p>
            </div>
            <div class="currencyDiv_center">
              {{ $t("PurchaseServices.unlimited") }}
            </div>
            <div class="currencyDiv_right">
              {{ $t("PurchaseServices.unlimited") }}
            </div>
          </div>
          <div class="currencyDiv">
            <!--            云储存空间-->
            <div class="currencyDiv_left currencyDiv_center_left">
              <p>{{ $t("PurchaseServices.Cloudstoragespace") }}</p>
            </div>
            <div class="currencyDiv_center currencyDiv_center_center">
              <p class="toppp">
                {{ $t("PurchaseServices.Cloudstoragespace1") }}
              </p>
              <p>{{ $t("PurchaseServices.Cloudstoragespace2") }}</p>
            </div>
            <div class="currencyDiv_right currencyDiv_center_center">
              <p class="toppp">
                {{ $t("PurchaseServices.Cloudstoragespace3") }}
              </p>
              <p>{{ $t("PurchaseServices.Cloudstoragespace2") }}</p>
            </div>
          </div>
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.Exclusivecustomerservice") }}</p>
            </div>
            <div class="currencyDiv_center">
              <p><svg-icon icon-class="icon_No" class="erricon"></svg-icon></p>
            </div>
            <div class="currencyDiv_right">
              <p><svg-icon icon-class="Yes" class="successicon"></svg-icon></p>
            </div>
          </div>
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.TeamFunctionality") }}</p>
            </div>
            <div class="currencyDiv_center">
              <p><svg-icon icon-class="icon_No" class="erricon"></svg-icon></p>
            </div>
            <div class="currencyDiv_right">
              <p><svg-icon icon-class="Yes" class="successicon"></svg-icon></p>
            </div>
          </div>
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.Glossary") }}</p>
            </div>
            <div class="currencyDiv_center">
              <p><svg-icon icon-class="icon_No" class="erricon"></svg-icon></p>
            </div>
            <div class="currencyDiv_right">
              <p><svg-icon icon-class="Yes" class="successicon"></svg-icon></p>
            </div>
          </div>
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.TranslationMemoryLibrary") }}</p>
            </div>
            <div class="currencyDiv_center">
              <p><svg-icon icon-class="icon_No" class="erricon"></svg-icon></p>
            </div>
            <div class="currencyDiv_right">
              <p><svg-icon icon-class="Yes" class="successicon"></svg-icon></p>
            </div>
          </div>
          <div class="currencyDiv">
            <div class="currencyDiv_left">
              <p>{{ $t("PurchaseServices.Automaticerrorchecking") }}</p>
            </div>
            <div class="currencyDiv_center">
              <p><svg-icon icon-class="Yes" class="successicon"></svg-icon></p>
            </div>
            <div class="currencyDiv_right">
              <p><svg-icon icon-class="Yes" class="successicon"></svg-icon></p>
            </div>
          </div>
          <div class="currencyDiv">
            <div class="currencyDiv_left currencyDiv_btm_left">
              <p>{{ $t("PurchaseServices.Recommendedusagescenarios") }}</p>
            </div>
            <div class="currencyDiv_center currencyDiv_btm_center">
              <p>{{ $t("PurchaseServices.Recommendedusagescenarios1") }}</p>
            </div>
            <div class="currencyDiv_right currencyDiv_btm_right">
              <p v-html="$t('PurchaseServices.Recommendedusagescenarios2')"></p>
            </div>
          </div>
        </div>
        <div class="content_center_right">
          <div class="content_center_right_top">
            {{ $t("PurchaseServices.EnterprisePLUSEdition") }}
          </div>
          <div class="content_center_right_btm">
            <p>{{ $t("PurchaseServices.Ondemandcustomization") }}</p>
            <el-button type="primary" @click="contactNow">{{
              $t("PurchaseServices.Contactimmediately")
            }}</el-button>
            <p class="tipsp">
              {{ $t("PurchaseServices.Contactimmediately1") }}
            </p>
          </div>
        </div>
      </div>
      <div class="content_center_tips">
        <p>{{ $t("PurchaseServices.illustrate") }}</p>
        <p>{{ $t("PurchaseServices.illustrate6") }}</p>
        <p>{{ $t("PurchaseServices.illustrate7") }}</p>
        <p>{{ $t("PurchaseServices.illustrate8") }}</p>
        <p>{{ $t("PurchaseServices.illustrate9") }}</p>
      </div>
      <div class="content_bottom_callme">
        <div>
          <svg-icon icon-class="mailbox" class="icon"></svg-icon>
          <p>{{ $t("PurchaseServices.email") }}：services@wiitrans.com</p>
        </div>
        <div>
          <svg-icon icon-class="PhoneCall" class="icon"></svg-icon>
          <p>{{ $t("PurchaseServices.phone") }}：400-060-8287</p>
        </div>
        <!-- <div>
          <svg-icon icon-class="Service" class="icon"></svg-icon>
          <p>在线客服：<span @click="callmeServices">点我联系在线客服</span></p>
        </div> -->
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitle"
      :showfooter="false"
      :destroyonclose="true"
      v-if="dialogVisible"
    >
      <div class="dialogCenter">
        <div class="dialogCenter_top">
          <div class="dialogCenter_top_left">
            <!--            产品-->
            <p class="topp">{{ $t("PurchaseServices.product") }}</p>
            <p class="btmp">
              <span> {{ dialogData.titlep }}</span>
              <span style="margin-left: 10px">
                {{ " ¥ " + dialogData.Pricep }}</span
              >
            </p>
          </div>
          <div class="dialogCenter_top_right">
            <div class="dialogCenter_top_right_div">
              <p class="topp">{{ $t("PurchaseServices.periodofvalidity") }}</p>
              <p class="btmp">
                {{ dialogData.timep + $t("PurchaseServices.Months") }}
              </p>
            </div>
            <div class="dialogCenter_top_right_div">
              <p class="topp">{{ $t("PurchaseServices.preferential") }}</p>
              <p class="btmp">
                {{ $t("PurchaseServices.nothave") }}
              </p>
            </div>
            <div>
              <p class="topp">{{ $t("PurchaseServices.Paidamount") }}</p>
              <p class="btmp">{{ "¥ " + dialogData.Pricep }}</p>
            </div>
          </div>
        </div>
        <div class="dialogCenter_btm">
          <div class="dialogCenter_btm_top">
            <!--            支付方式-->
            <p>{{ $t("PurchaseServices.Paymentmethod") }}</p>
            <div class="dialogCenter_btm_top_btm">
              <div
                :class="showclickWechat ? 'click_dialogCenter_btm_top_div' : ''"
                @click="changeWechat"
              >
                <svg-icon icon-class="Wechat" class="icon"></svg-icon>
              </div>
              <div
                :class="
                  showclickWechat == false
                    ? 'click_dialogCenter_btm_top_div'
                    : ''
                "
                @click="changeWechat"
              >
                <svg-icon icon-class="Alipay" class="icon"></svg-icon>
              </div>
            </div>
          </div>
          <div class="dialogCenter_btm_center"></div>
          <div class="dialogCenter_btm_btm">
            <div
              :class="
                showclickWechat
                  ? 'dialogCenter_btm_btm_left'
                  : 'dialogCenter_btm_btm_left_1'
              "
              :style="QrcodeStyle"
            >
              <div v-if="showclickWechat" ref="qrCodeDiv"></div>
              <img :src="purl" alt="" v-else />
            </div>
            <div class="dialogCenter_btm_btm_right">
              <!--              微信扫码支付-->
              <p v-if="showclickWechat">
                {{ $t("PurchaseServices.WeChatscanningpayment") }}
              </p>
              <p v-else>
                {{ $t("PurchaseServices.Alipaycodescanningpayment") }}
              </p>
              <p class="Priceppp">
                {{ dialogData.Pricep
                }}<span>{{ $t("PurchaseServices.yuan") }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </DialogComVue>
    <DialogComVue
      :dialogVisible="dialogContactVisible"
      @determineClick="determineContactClick"
      @changeDialogVisible="changeDialogContactVisible"
      :width="'448px'"
      :top="'10%'"
      :title="$t('PurchaseServices.Contactyou')"
      :showfooter="true"
      :destroyonclose="true"
      v-if="dialogContactVisible"
    >
      <div class="dialogContactContents">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="70px"
          class="demo-ruleForm"
          label-position="top"
          :hide-required-asterisk="false"
          @keydown.enter.prevent
        >
          <el-form-item
            :label="$t('PurchaseServices.Contactyou')"
            prop="contact_fname"
            :rules="[
              {
                required: true,
                message: $t('PurchaseServices.Contactyou'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="ruleForm.contact_fname"
              :placeholder="$t('PurchaseServices.Contactyou')"
              class="topinput"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('PurchaseServices.corporatename')"
            prop="contact_lname"
            :rules="[
              {
                required: true,
                message: $t('PurchaseServices.entercorporatename'),
                trigger: 'blur',
              },
            ]"
          >
            <div class="inputbtn">
              <el-input
                v-model="ruleForm.contact_lname"
                :placeholder="$t('PurchaseServices.entercorporatename')"
                class="btminput"
                maxlength="50"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('PurchaseServices.Emails')"
            prop="contact_email"
            :rules="[
              {
                required: true,
                message: $t('PurchaseServices.enteremail'),
                trigger: 'blur',
              },
              {
                type: 'email',
                message: $t('PurchaseServices.enterthecorrectemail'),
                trigger: 'blur',
              },
            ]"
          >
            <div class="inputbtn">
              <el-input
                v-model="ruleForm.contact_email"
                :placeholder="$t('PurchaseServices.enteryouremail')"
                class="btminput"
                maxlength="30"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('PurchaseServices.Othercontactinformation')"
            prop="contact_text"
          >
            <div class="inputbtn">
              <el-input
                v-model="ruleForm.contact_text"
                :placeholder="$t('PurchaseServices.PhoneWeChatQQ')"
                class="btminput"
                maxlength="15"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import QRCode from "qrcodejs2";
import { pay_vip_code, get_pay_result, submit_contact } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
export default {
  components: { DialogComVue },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("info"));
    this.dialogtitle = this.$t("PurchaseServices.payment");
  },
  data() {
    return {
      info: {},
      dialogVisible: false,
      dialogtitle: "",
      showclickWechat: true,
      purl: "",
      interval: null,
      dialogData: {},
      QrcodeStyle: "",
      dialogContactVisible: false,
      ruleForm: {
        contact_fname: "",
        contact_lname: "",
        contact_email: "",
        contact_text: "",
      },
      submit_loading: false,
    };
  },
  methods: {
    /**
     *
     * @param {*获取二维码} url
     */
    bindQRCode(url) {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrCodeDiv, {
          text: url, //url地址  文本等需要转换为二维码的内容
          width: 140,
          height: 140,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
        hideLoading();
      });
    },
    /**
     *
     * @param {* 轮询 支付结果} pid
     */
    get_pay_result(pid) {
      get_pay_result({
        action: "get_pay_result",
        pid: pid,
      }).then((res) => {
        console.log(res);
        if (!res) {
          return;
        }
        if (res.flag == 0) {
          return;
        }
        this.$messages("success", res.msg);
        this.changeDialogVisible(false);
      });
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.showclickWechat = true;
      clearInterval(this.interval);
      this.interval = null;
    },
    determineClick() {},
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogContactVisible(val) {
      this.dialogContactVisible = val;
      this.$refs["ruleForm"].resetFields();
    },
    determineContactClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // console.log("submit!");
          if (this.submit_loading == false) {
            this.submit_loading = true;
            submit_contact({
              action: "submit_contact",
              user: this.ruleForm.contact_fname,
              address: this.ruleForm.contact_lname,
              email: this.ruleForm.contact_email,
              tel: this.ruleForm.contact_text,
            }).then((res) => {
              this.submit_loading = false;
              if (!res) {
                return;
              }
              this.dialogContactVisible = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     *
     * @param {*获取 支付宝 支付二维码} item
     */
    pay_vip_code() {
      pay_vip_code({
        action: "pay_vip_code",
        pay_type: this.showclickWechat ? "1" : "2",
      }).then((res) => {
        if (!res) {
          return;
        }
        this.dialogVisible = true;
        if (this.showclickWechat) {
          this.QrcodeStyle = "";
          this.bindQRCode(res.code_url);
        } else {
          this.purl = res.purl;
          let that = this;
          let imgObj = new Image();
          imgObj.src = res.purl;
          imgObj.onload = () => {
            if (imgObj.width > 0 && imgObj.height > 0) {
              that.QrcodeStyle = {
                width: imgObj.width / 2 + "px",
                height: imgObj.height / 2 + "px",
              };
            }
          };
          hideLoading();
        }
        this.interval = setInterval(() => {
          setTimeout(this.get_pay_result(res.pid), 0);
        }, 5000);
      });
    },

    /**
     *
     * @param {*点击立即购买} item
     */
    gobuy(item) {
      // console.log(item);
      //提示弹窗
      this.$confirm(
        this.$t("CommonName.purchaseMessage"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          showCancelButton: false,
          showConfirmButton: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {});
      // showLoading(this.$t("CommonName.loadingmessage"));
      // this.dialogData = item;
      // this.pay_vip_code();
    },
    /**
     * 切换支付方式
     */
    changeWechat() {
      this.showclickWechat = !this.showclickWechat;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.pay_vip_code();
    },
    callmeServices() {
      let href =
        "https://tb.53kf.com/code/client/02915166b11237caaf3a887661acdfa60/1";
      window.open(href);
    },
    contactNow() {
      this.dialogContactVisible = true;
    },
  },
  beforeDestroy() {
    //清除轮询
    clearInterval(this.interval);
    this.interval = null;
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
.content_center {
  // margin: 0 24px 0 26px;
  // padding: 32px 0 29px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 99%;
  overflow-y: auto;

  .content_center_all {
    // height: 770px;
    // overflow-y: auto;
    margin: 0 auto;
  }
  .content_center_tips {
    margin-top: 30px;
    p {
      text-align: left;
      color: #d5d7e1;
      font-size: 14px;
      line-height: 29px;
    }
  }

  .content_center_top {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .content_center_right_top {
    width: 326px;
    height: 66px;
    text-align: center;
    line-height: 66px;
    background: rgba(173, 113, 70, 0.74);
    color: #ffffff;
    font-size: 24px;
    border: 1px solid;
    border-color: #515464;
  }
  .content_center_left {
    .currencyDiv {
      display: flex;
      .erricon {
        color: #d04848;
      }
      .successicon {
        color: #4eec6e;
      }
      .currencyDivcur {
        height: 66px !important;
        line-height: 66px !important;
        color: #ffffff !important;
        font-size: 24px !important;
      }
      .currencyDiv_center1 {
        background: #6c89c5 !important;
      }
      .currencyDiv_left1 {
        border: none !important;
        background: none !important;
        height: 66px !important;
        width: 146px !important;
      }

      .currencyDiv_top_left {
        height: 103px !important;
        background: none !important;
      }
      .currencyDiv_top_center {
        height: 103px !important;
        line-height: 103px !important;
        // background: #434b65 !important;
      }
      .currencyDiv_top_right {
        height: 103px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          font-size: 28px;
          span {
            font-size: 14px;
          }
        }
      }
      .currencyDiv_center_left {
        height: 71px !important;
        line-height: 71px !important;
      }
      .currencyDiv_center_center {
        height: 71px !important;
        p {
          color: #adb0bc;
          line-height: normal;
          font-size: 12px;
        }
        .toppp {
          color: #ffffff;
          font-size: 14px;
          margin: 13px 0 10px 0;
        }
      }
      .currencyDiv_btm_left {
        height: 120px !important;
        line-height: 120px !important;
        border-bottom: 1px solid #515464 !important;
      }
      .currencyDiv_btm_center {
        height: 120px !important;
        background-color: #434b65 !important;
        border-bottom: 1px solid #515464 !important;
        p {
          margin: 22px 19px 19px 20px;
          line-height: normal;
          text-align: left;
        }
      }
      .currencyDiv_btm_right {
        height: 120px !important;
        background-color: #4f4952 !important;
        border-bottom: 1px solid #515464 !important;
        p {
          margin: 11px 20px 8px 20px;
          line-height: normal;
          text-align: left;
        }
      }
      .currencyDiv_right1 {
        background-color: #cc8655 !important;
      }
      .currencyDiv_left {
        width: 145px;
        height: 39px;
        background-color: #454758;
        border: 1px solid;
        border-color: #515464;
        border-bottom: none;
        border-right: none;
        display: flex;
        color: #d5d7e1 !important;
        line-height: 40px;
        color: #ffffff;
        font-size: 14px;
        p {
          margin-left: 16px;
        }
      }
      .currencyDiv_center {
        width: 400px;
        height: 39px;
        background-color: #434b65;
        border: 1px solid;
        border-color: #515464;
        border-bottom: none;
        border-right: none;
        display: flex;
        flex-direction: column;
        line-height: 40px;
        color: #ffffff;
        font-size: 14px;
      }
      .currencyDiv_right {
        width: 400px;
        height: 39px;
        background-color: #4f4952;
        border: 1px solid;
        border-color: #515464;
        border-bottom: none;
        border-right: none;
        display: flex;
        flex-direction: column;
        line-height: 40px;
        color: #ffffff;
        font-size: 14px;
      }
    }
    .content_center_right_top {
      border-right: none;
    }
    .content_center_left_left {
      width: 145px;
      height: 66px;
    }
  }
  .el-button {
    width: 157px;
    height: 32px;
    border-radius: 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(67, 28, 0, 0.16);
  }
  .content_center_right {
    .content_center_right_btm {
      width: 326px;
      height: 616px;
      background-color: #3e3e4d;
      border: 1px solid;
      border-color: #515464;
      border-top: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        color: #ffffff;
        font-size: 14px;
        margin: 25px 0 16px 0;
      }
      .tipsp {
        margin: 0 22px;
        margin-top: 21px;
      }
    }
  }
  .dialogContactContents {
    padding: 16px 24px;
    ::v-deep .el-form-item__label {
      line-height: 24px;
      margin-top: 8px;
      color: #adb0bc;
      font-size: 14px;
      padding: 0;
    }
    ::v-deep .el-input__inner {
      width: 100%;
    }
    ::v-deep .el-form-item {
      margin-bottom: 12px;
    }
  }
}
</style>
