<!--
 * @Description: 错误检查  表体
 * @version: 
 * @Author: Carful
 * @Date: 2023-04-12 17:33:20
 * @LastEditors: Carful
 * @LastEditTime: 2023-05-06 09:48:15
-->
<template>
  <div class="bodydiv" id="bodydiv" :class="setBackground">
    <div class="onediv borderdiv_body" @click="tabrowClick(source)">
      {{ source.index }}
    </div>
    <div
      v-if="
        this.steptabTable === 6 ||
        this.steptabTable === 7 ||
        this.steptabTable === 8 ||
        this.steptabTable === 9
      "
      class="three borderdiv_body"
      @click="tabrowClick(source)"
    >
      {{ getTask }}
    </div>
    <div
      class="twodiv borderdiv_body"
      :class="
        this.steptabTable === 6 || this.steptabTable === 7
          ? 'twodiv1'
          : this.steptabTable === 8 || this.steptabTable === 9
          ? 'twodiv2'
          : this.steptabTable === 3
          ? 'twodiv3'
          : 'twodiv4'
      "
      @click="tabrowClick(source)"
    >
      {{ source.errorData }}
    </div>
    <div
      class="fourdiv borderdiv_body"
      v-if="
        this.steptabTable === 3 ||
        this.steptabTable === 6 ||
        this.steptabTable === 7
      "
    >
      <el-tooltip
        class="item"
        effect="dark"
        placement="left"
        :enterable="false"
        :visible-arrow="false"
        slot="reference"
        v-if="source.isTerm"
      >
        <div slot="content">
          <span>{{ $t("OverwriteOperation.termSendTip") }}</span>
        </div>
        <svg-icon
          :icon-class="'send'"
          :class="'iconColor'"
          class="icon"
          @mousedown.native="handleMousedown"
          @click="errorCellClick(source)"
        ></svg-icon>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import mixins from "@/utils/dispathIndex.js";

export default {
  name: "ErrorCheckingTableBody",
  mixins: [mixins],
  computed: {
    setBackground() {
      if (
        this.source.sentence_id == this.tabrowData.sentence_id &&
        this.source.errorData == this.tabrowData.errorData
      ) {
        return "backgroundtrue";
      } else {
        return "";
      }
    },
    getTask() {
      let name = "";
      this.projectList.forEach((i) => {
        if (i.id === this.source.id) {
          name = i.task;
        }
      });

      return name;
    },
  },
  props: {
    source: {
      type: Object,
      default: () => {},
    },
    currentRegion: {
      type: Object,
      default() {
        return {};
      },
    },
    tabrowData: {
      type: Object,
      default() {
        return {};
      },
    },
    steptabTable: {
      type: Number,
      default: 0,
    },
    projectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    tabrowClick(row) {
      // OverwriteOperation
      // console.log(this.steptabTable);
      let str = "";
      if (this.steptabTable === 1) {
        str = "OverwriteOperation";
      } else if (this.steptabTable === 2) {
        str = "keep-state";
      } else if (this.steptabTable === 4) {
        str = "VideoDetails"; //个人
      } else if (this.steptabTable === 5) {
        str = "YoutubeVideoOperation"; //youtube
      } else if (this.steptabTable === 3) {
        str = "ProofingOperation";
      } else if (this.steptabTable === 6) {
        str = "BatchProofreading"; //批量 校审
      } else if (this.steptabTable === 7) {
        str = "BatchTranslation"; //批量 翻译
      } else if (this.steptabTable === 8) {
        str = "BatchTranscription"; //批量 转写
      } else if (this.steptabTable === 9) {
        str = "BatchDubbingProof"; //批量 配音审阅
      }
      this.dispatch(str, "tabrowclick", row);
    },
    handleMousedown(event) {
      event.preventDefault();
    },
    errorCellClick(row) {
      // OverwriteOperation
      // console.log(this.steptabTable);
      let str = "";
      if (this.steptabTable === 1) {
        str = "OverwriteOperation";
      } else if (this.steptabTable === 2) {
        str = "keep-state";
      } else if (this.steptabTable === 4) {
        str = "VideoDetails"; //个人
      } else if (this.steptabTable === 5) {
        str = "YoutubeVideoOperation"; //youtube
      } else if (this.steptabTable === 3) {
        str = "ProofingOperation";
      } else if (this.steptabTable === 6) {
        str = "BatchProofreading"; //批量 校审
      } else if (this.steptabTable === 7) {
        str = "BatchTranslation"; //批量 翻译
      } else if (this.steptabTable === 8) {
        str = "BatchTranscription"; //批量 转写
      } else if (this.steptabTable === 9) {
        str = "BatchDubbingProof"; //批量 配音审阅
      }
      this.dispatch(str, "errorCellClick", row);
    },
  },
};
</script>
<style scoped lang="scss">
.backgroundtrue {
  background: #3d7ab0;
}

.bodydiv {
  display: flex;
  font-size: 13px;
  box-sizing: border-box;
  min-height: 40px;
  color: rgb(203, 215, 232) !important;

  .onediv {
    width: 60px;
    border-left: 1px solid #515464;
  }

  .twodiv {
    justify-content: left !important;
    text-align: left;
    padding-left: 10px;
  }

  .twodiv1 {
    width: calc(100% - 165px);
  }

  .twodiv2 {
    width: calc(100% - 120px);
  }

  .twodiv3 {
    width: calc(100% - 105px);
  }

  .twodiv4 {
    width: calc(100% - 60px);
  }
  .three {
    width: 60px;
    // border-left: 1px solid #515464;
  }

  .borderdiv_body {
    border-right: 1px solid #515464;
    border-bottom: 1px solid #515464;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .fourdiv {
    width: 45px;
    // border-right: none;
  }
}
</style>
