<!--
 * @Description: 购买服务
 * @version: 
 * @Author: Carful
 * @Date: 2022-12-09 09:46:03
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-27 10:08:49
-->
<template>
  <div class="center_div">
    <div class="center_div_content">
      <div class="banner_div" v-if="showBanner">
        <img :src="require('@/assets/imgs/Bc0.png')" alt="" />
        <div
          class="clickdiv"
          @click="
            gobuy({
              Pricep: '9.9',
              timep: '3',
              titlep: $t('PurchaseServices.DeepExperiencePackage'),
              pay_action: 1,
              package_level: 1,
            })
          "
        ></div>
      </div>
      <!--      译制功能包-->
      <p class="titlep">
        {{ $t("PurchaseServices.Translationfunctionpackage")
        }}<span @click="goVIP" style="cursor: pointer">{{
          $t("PurchaseServices.Translationfunctionpackageexplain")
        }}</span>
      </p>
      <p class="tipsp">
        {{ $t("PurchaseServices.TranslationfunctionpackageMessage") }}
      </p>
      <div class="content_top_div">
        <div
          class="back_div back_divimg"
          v-for="i in contentTopDivData"
          :key="i.id"
        >
          <div class="back_content_div">
            <p class="titlep1">{{ i.titlep }}</p>
            <p class="timep">
              {{
                "(" +
                $t("PurchaseServices.periodofvalidity") +
                i.timep +
                $t("PurchaseServices.Months") +
                ")"
              }}
            </p>
            <div class="btm_div">
              <p class="Pricep">
                ¥ <span>{{ i.Pricep }}</span>
              </p>
              <p class="averagepricep">
                {{
                  $t("PurchaseServices.makeanappointment") +
                  i.averagepricep +
                  $t("PurchaseServices.yuanminute")
                }}
              </p>
              <!--              立即购买-->
              <el-button @click="gobuy(i)"
                >{{ $t("PurchaseServices.buynow") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--      AI 配音包-->
      <p class="titlep">
        {{ $t("PurchaseServices.AIdubbingpackage")
        }}<span @click="goVIP" style="cursor: pointer">
          {{ $t("PurchaseServices.Translationfunctionpackageexplain") }}</span
        >
      </p>
      <p class="tipsp">
        {{ $t("PurchaseServices.AIdubbingpackageexplain") }}
      </p>
      <p class="tipsp_p">
        {{ $t("PurchaseServices.AIdubbingpackageexplain1") }}
      </p>
      <p class="tipsp_p">
        {{ $t("PurchaseServices.AIdubbingpackageexplain2") }}
      </p>
      <p class="tipsp_p">
        {{ $t("PurchaseServices.AIdubbingpackageexplain3") }}
      </p>
      <p class="tipsp_p" style="margin-bottom: 24px">
        <span>{{ $t("PurchaseServices.AIdubbingpackageexplain4") }}</span>
        <span
          ><a
            style="color: #46adff"
            target="_blank"
            href="https://tb.53kf.com/code/client/02915166b11237caaf3a887661acdfa60/1"
            >{{ $t("PurchaseServices.AIdubbingpackageexplain5") }}</a
          ></span
        >
        <span>{{ $t("PurchaseServices.AIdubbingpackageexplain6") }}</span>
      </p>
      <div class="content_center_div">
        <div
          class="back_div back_divimg"
          v-for="i in contentCenterDivData"
          :key="i.id"
        >
          <div class="back_content_div">
            <p class="titlep1">{{ i.titlep }}</p>
            <p class="timep">
              {{
                "(" +
                $t("PurchaseServices.periodofvalidity") +
                i.timep +
                $t("PurchaseServices.Months") +
                ")"
              }}
            </p>
            <div class="btm_div">
              <p class="Pricep">
                ¥ <span>{{ i.Pricep }}</span>
              </p>
              <p class="averagepricep">
                {{ i.averagepricep + $t("PurchaseServices.yuanminute") }}
              </p>
              <!--              立即购买-->
              <el-button @click="gobuy(i)"
                >{{ $t("PurchaseServices.buynow") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--      硬字幕涂抹-->
      <p class="titlep">
        {{ $t("PurchaseServices.AIDuabPackage")
        }}<span @click="goVIP" style="cursor: pointer">
          {{ $t("PurchaseServices.Translationfunctionpackageexplain") }}</span
        >
      </p>
      <p class="tipsp">
        {{ $t("PurchaseServices.AIDuabPackageExplain") }}
      </p>
      <div class="content_center_div">
        <div class="back_div back_divimg2" v-for="i in daubDivData" :key="i.id">
          <div class="back_content_div">
            <p class="titlep1">{{ i.titlep }}</p>
            <p class="timep">
              {{
                "(" +
                $t("PurchaseServices.periodofvalidity") +
                i.timep +
                $t("PurchaseServices.Months") +
                ")"
              }}
            </p>
            <div class="btm_div">
              <p class="Pricep">
                ¥ <span>{{ i.Pricep }}</span>
              </p>
              <p class="averagepricep">
                {{ i.averagepricep + $t("PurchaseServices.yuanminute") }}
              </p>
              <!--              立即购买-->
              <el-button @click="gobuy(i)"
                >{{ $t("PurchaseServices.buynow") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--      文本配音包-->
      <p class="titlep">
        {{ $t("PurchaseServices.ComicFlipBag")
        }}<span @click="goVIP" style="cursor: pointer">{{
          $t("PurchaseServices.Translationfunctionpackageexplain")
        }}</span>
      </p>
      <p class="tipsp">
        {{ $t("PurchaseServices.ComicFlipBag1") }}
      </p>
      <div class="content_bottom_div">
        <div class="back_div back_divimg">
          <div class="back_content_div">
            <p class="titlep1">
              {{ $t("PurchaseServices.TextdubbingCharactercards")
              }}<span>{{ $t("PurchaseServices.Validityperiod") }}</span>
            </p>
            <p class="timep">{{ $t("PurchaseServices.Validityperiod1") }}</p>
            <div class="btm_div">
              <p class="Pricep">
                ¥ <span>{{ 100 }}</span>
              </p>
              <p class="averagepricep">
                {{ $t("PurchaseServices.Validityperiod2") }}
              </p>
              <el-button
                @click="
                  gobuy({
                    Pricep: '100',
                    timep: '1',
                    titlep: $t('PurchaseServices.ValidityperiodTitle'),
                    pay_action: 3,
                    package_level: 1,
                  })
                "
                >{{ $t("PurchaseServices.buynow") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--      说明-->
      <div class="content_bottom_tips">
        <p>{{ $t("PurchaseServices.illustrate") }}</p>
        <p>{{ $t("PurchaseServices.illustrate1") }}</p>
        <p>{{ $t("PurchaseServices.illustrate2") }}</p>
        <p>{{ $t("PurchaseServices.illustrate3") }}</p>
        <p>{{ $t("PurchaseServices.illustrate4") }}</p>
        <p>{{ $t("PurchaseServices.illustrate5") }}</p>
      </div>
      <div class="content_bottom_callme">
        <div>
          <svg-icon icon-class="mailbox" class="icon"></svg-icon>
          <p>{{ $t("PurchaseServices.email") }}：services@wiitrans.com</p>
        </div>
        <div>
          <svg-icon icon-class="PhoneCall" class="icon"></svg-icon>
          <p>{{ $t("PurchaseServices.phone") }}：400-060-8287</p>
        </div>
        <!-- <div>
          <svg-icon icon-class="Service" class="icon"></svg-icon>
          <p>在线客服：<span @click="callmeServices">点我联系在线客服</span></p>
        </div> -->
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitle"
      :showfooter="false"
      :destroyonclose="true"
      v-if="dialogVisible"
    >
      <div class="dialogCenter">
        <div class="dialogCenter_top">
          <div class="dialogCenter_top_left">
            <!--            产品-->
            <p class="topp">{{ $t("PurchaseServices.product") }}</p>
            <p class="btmp">
              <span> {{ dialogData.titlep }}</span>
              <span style="margin-left: 10px">
                {{ " ¥ " + dialogData.Pricep }}</span
              >
            </p>
          </div>
          <div class="dialogCenter_top_right">
            <div class="dialogCenter_top_right_div">
              <p class="topp">{{ $t("PurchaseServices.periodofvalidity") }}</p>
              <p class="btmp">
                {{ dialogData.expiration_date + $t("PurchaseServices.Months") }}
              </p>
            </div>
            <div class="dialogCenter_top_right_div">
              <p class="topp">{{ $t("PurchaseServices.preferential") }}</p>
              <p class="btmp">
                {{ $t("PurchaseServices.nothave") }}
              </p>
            </div>
            <div>
              <p class="topp">{{ $t("PurchaseServices.Paidamount") }}</p>
              <p class="btmp">{{ "¥ " + dialogData.pay_money }}</p>
            </div>
          </div>
        </div>
        <div class="dialogCenter_btm">
          <div class="dialogCenter_btm_top">
            <!--            支付方式-->
            <p>{{ $t("PurchaseServices.Paymentmethod") }}</p>
            <div class="dialogCenter_btm_top_btm">
              <div
                :class="showclickWechat ? 'click_dialogCenter_btm_top_div' : ''"
                @click="changeWechat"
              >
                <svg-icon icon-class="Wechat" class="icon"></svg-icon>
              </div>
              <div
                :class="
                  showclickWechat == false
                    ? 'click_dialogCenter_btm_top_div'
                    : ''
                "
                @click="changeWechat"
              >
                <svg-icon icon-class="Alipay" class="icon"></svg-icon>
              </div>
            </div>
          </div>
          <div class="dialogCenter_btm_center"></div>
          <div class="dialogCenter_btm_btm">
            <div
              :class="
                showclickWechat
                  ? 'dialogCenter_btm_btm_left'
                  : 'dialogCenter_btm_btm_left_1'
              "
              :style="QrcodeStyle"
            >
              <div v-if="showclickWechat" ref="qrCodeDiv"></div>
              <img :src="purl" alt="" v-else />
            </div>

            <div class="dialogCenter_btm_btm_right">
              <!--              微信扫码支付-->
              <p v-if="showclickWechat">
                {{ $t("PurchaseServices.WeChatscanningpayment") }}
              </p>
              <p v-else>
                {{ $t("PurchaseServices.Alipaycodescanningpayment") }}
              </p>
              <p class="Priceppp">
                {{ dialogData.pay_money
                }}<span>{{ $t("PurchaseServices.yuan") }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import QRCode from "qrcodejs2";
import { pay_package_code, get_pay_result } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";

export default {
  components: {
    DialogComVue,
  },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("info"));
    // console.log(this.info);
    if (this.info.deep_count == "1") {
      this.showBanner = false;
    } else {
      this.showBanner = true;
    }
    this.dialogtitle = this.$t("PurchaseServices.payment");
    //5视频配音包
    this.contentCenterDivData = [
      {
        id: 1,
        titlep: this.$t("PurchaseServices.titlep7"),
        timep: "36",
        Pricep: 900,
        averagepricep: "3.0",
        lang: false,
        pay_action: 5,
        package_level: 1,
      },
      {
        id: 2,
        titlep: this.$t("PurchaseServices.titlep8"),
        timep: "36",
        Pricep: 3000,
        averagepricep: "2.5",
        lang: false,
        pay_action: 5,
        package_level: 2,
      },
      {
        id: 3,
        titlep: this.$t("PurchaseServices.titlep9"),
        timep: "36",
        Pricep: 12000,
        averagepricep: "2.0",
        lang: true,
        pay_action: 5,
        package_level: 3,
      },
    ];
    this.daubDivData = [
      {
        id: 1,
        titlep: this.$t("PurchaseServices.titlep10"),
        timep: "36",
        Pricep: 450,
        averagepricep: "1.5",
        lang: false,
        pay_action: 6,
        package_level: 1,
      },
      {
        id: 2,
        titlep: this.$t("PurchaseServices.titlep11"),
        timep: "36",
        Pricep: 1560,
        averagepricep: "1.3",
        lang: false,
        pay_action: 6,
        package_level: 2,
      },
      {
        id: 3,
        titlep: this.$t("PurchaseServices.titlep12"),
        timep: "36",
        Pricep: 6000,
        averagepricep: "1.0",
        lang: true,
        pay_action: 6,
        package_level: 3,
      },
    ];
    this.contentTopDivData = [
      {
        id: 1,
        titlep: this.$t("PurchaseServices.titlep1"),
        timep: "36",
        Pricep: 150,
        averagepricep: "0.5",
        lang: false,
        pay_action: 2,
        package_level: 1,
      },
      {
        id: 2,
        titlep: this.$t("PurchaseServices.titlep2"),
        timep: "36",
        Pricep: 480,
        averagepricep: "0.4",
        lang: false,
        pay_action: 2,
        package_level: 2,
      },
      {
        id: 3,
        titlep: this.$t("PurchaseServices.titlep3"),
        timep: "36",
        Pricep: 1200,
        averagepricep: "0.2",
        lang: true,
        pay_action: 2,
        package_level: 3,
      },
    ];
  },
  data() {
    return {
      info: {},
      showBanner: true, // 是否显示 深度体验包
      pay_action: "",
      dialogVisible: false,
      dialogtitle: "",
      dialogData: {},
      showclickWechat: true,
      interval: null,
      purl: "",
      contentTopDivData: [], //译制功能包
      contentCenterDivData: [], //AI 视频配音包
      daubDivData: [], //硬字幕涂抹包
      QrcodeStyle: "",
    };
  },
  beforeDestroy() {
    //清除轮询
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    /**
     *
     * @param {*获取二维码} url
     */
    bindQRCode(url) {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrCodeDiv, {
          text: url, //url地址  文本等需要转换为二维码的内容
          width: 140,
          height: 140,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
        hideLoading();
      });
    },
    /**
     *
     * @param {* 轮询 支付结果} pid
     */
    get_pay_result(pid) {
      get_pay_result({
        action: "get_pay_result",
        pid: pid,
      }).then((res) => {
        // console.log(res);
        if (!res) {
          return;
        }
        if (res.flag == 0) {
          return;
        }
        if (this.pay_action == "1" && this.showBanner) {
          this.showBanner = false;
        }
        this.$messages("success", res.msg);
        this.changeDialogVisible(false);
      });
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.showclickWechat = true;
      clearInterval(this.interval);
      this.interval = null;
    },
    determineClick() {},
    /**
     *
     * @param {*获取 支付宝 支付二维码} item
     */
    pay_package_code(item) {
      pay_package_code({
        action: "pay_package_code",
        pay_type: this.showclickWechat ? "1" : "2",
        pay_action: item.pay_action,
        package_level: item.package_level,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.dialogVisible = true;
        // this.bindQRCode(res.code_url);
        this.dialogData.pay_discount = res.pay_discount;
        this.dialogData.pay_money = res.pay_money;
        this.dialogData.expiration_date = res.expiration_date;
        if (this.showclickWechat) {
          this.QrcodeStyle = "";
          this.bindQRCode(res.code_url);
        } else {
          this.purl = res.purl;
          let that = this;
          let imgObj = new Image();
          imgObj.src = res.purl;
          imgObj.onload = () => {
            if (imgObj.width > 0 && imgObj.height > 0) {
              that.QrcodeStyle = {
                width: imgObj.width / 2 + "px",
                height: imgObj.height / 2 + "px",
              };
            }
          };
          hideLoading();
        }

        this.interval = setInterval(() => {
          setTimeout(this.get_pay_result(res.pid), 0);
        }, 5000);
      });
    },
    /**
     *
     * @param {*点击立即购买} item
     */
    gobuy(item) {
      // console.log(item);
      //提示弹窗
      this.$confirm(
        this.$t("CommonName.purchaseMessage"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          showCancelButton: false,
          showConfirmButton: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {});
      // this.pay_action = item.pay_action;
      // showLoading(this.$t("CommonName.loadingmessage"));
      // this.dialogData = item;
      // this.dialogData.pay_discount = 0;
      // this.dialogData.pay_money = item.Pricep;
      // console.log(item.pay_action, "pay_action");
      // this.pay_package_code(item);
    },
    /**
     * 开通企业vip
     */
    goVIP() {
      sessionStorage.setItem("BuyServices", "second");
      this.$emit("goVIP", "second");
    },
    /**
     * 切换支付方式
     */
    changeWechat() {
      this.showclickWechat = !this.showclickWechat;
      showLoading(this.$t("CommonName.loadingmessage"));
      clearInterval(this.interval);
      this.interval = null;
      this.pay_package_code(this.dialogData);
    },
    callmeServices() {
      let href =
        "https://tb.53kf.com/code/client/02915166b11237caaf3a887661acdfa60/1";
      window.open(href);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";

.center_div {
  //   margin: 0 63px 0 35px;
  //   height: 100%;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // height: 100%;
  height: 99%;
  overflow-y: auto;

  .center_div_content {
    width: 1220px;

    margin: 0 auto;
  }

  .banner_div {
    padding-top: 40px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: contain;
      box-shadow: 0px 2px 10px #13275e;
    }

    .clickdiv {
      width: 150px;
      height: 60px;
      position: absolute;
      top: 70px;
      right: 47px;
      background-color: #13275e;
      cursor: pointer;
      opacity: 0;
    }
  }

  .titlep {
    margin-top: 30px;
    color: #ffffff;
    font-size: 18px;
    text-align: left;

    span {
      margin-left: 40px;
      color: #dd9702;
      font-size: 12px;
    }
  }

  .tipsp {
    color: #adb0bc;
    font-size: 14px;
    text-align: left;
    margin: 15px 0 8px 0;
  }

  .tipsp_p {
    color: #adb0bc;
    font-size: 14px;
    text-align: left;
  }

  .el-button {
    width: 102px;
    height: 32px;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .titlep1 {
    color: #ffffff;
    font-size: 24px;
  }

  .timep {
    color: #f8d9ad;
    font-size: 14px;
    margin: 8px 0 36px 0;
  }

  .Pricep {
    font-size: 14px;
    color: #ffffff;

    span {
      font-weight: 700;
      font-size: 40px;
    }
  }

  .back_div {
    width: 372px;
    height: 180px;
    border-radius: 12px;

    .back_content_div {
      margin: 19px 26px 16px 34px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .btm_div {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .averagepricep {
    color: #ffffff;
    font-size: 14px;
  }

  .content_top_div {
    display: flex;
    justify-content: space-between;

    .back_divimg {
      background-image: url("@/assets/imgs/Bc1.png");
      background-size: 100% 100%;
      box-shadow: 4px 4px 0 #9d622f;

      .el-button {
        box-shadow: 0px 3px 6px rgba(67, 28, 0, 0.16);
        background-image: linear-gradient(180deg, #ec4d00 0%, #a03400 100%);

        &:hover {
          background-image: linear-gradient(180deg, #a03400 0%, #ec4d00 100%);
        }

        &:active {
          box-shadow: 0px 3px 6px rgba(67, 28, 0, 0.4);
        }
      }
    }
  }

  .content_center_div {
    display: flex;
    justify-content: space-between;

    .el-button {
      box-shadow: 0px 2px 4px rgba(67, 28, 0, 0.1);
      background-image: linear-gradient(180deg, #4ee2ff 0%, #358fff 100%);

      &:hover {
        background-image: linear-gradient(180deg, #358fff 0%, #4ee2ff 100%);
      }

      &:active {
        box-shadow: 0px 2px 4px rgba(67, 28, 0, 0.4);
      }
    }

    .titlep1 {
      span {
        color: #dbebff;
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .timep {
      color: #e2edff;
      font-size: 14px;
    }

    .btm_div {
      width: 100%;
      justify-content: space-between;
    }

    .back_divimg {
      //margin-right: 50px;
      background-image: url("@/assets/imgs/Bc4.png");
      background-size: 100% 100%;
      box-shadow: 4px 4px 0px #6f71a7;
    }

    .back_divimg1 {
      background-image: url("@/assets/imgs/Bc3.png");
      background-size: 100% 100%;
      box-shadow: 4px 4px 0px #bb7a5b;
    }
    .back_divimg2 {
      //margin-right: 50px;
      background-image: url("@/assets/imgs/Bc5.png");
      background-size: 100% 100%;
      box-shadow: 4px 4px 0px #6f71a7;
    }
    /**
    设置折扣样式
    */
    .setshowshowdiscount {
      position: relative;

      .showdiscount {
        position: absolute;
        right: 0;
        top: 0;
        width: 69px;
        height: 36px;
        background-color: #ea4c33;
        border-radius: 0px 13px 0px 13px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-weight: 700;
          color: #fff9db;
          font-size: 18px;
        }
      }
    }
  }

  .content_bottom_div {
    display: flex;
    justify-content: space-between;

    .titlep1 {
      span {
        color: #dbebff;
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .timep {
      color: #ffffff;
      font-size: 16px;
    }

    .el-button {
      box-shadow: 0px 2px 4px rgba(67, 28, 0, 0.1);
      background-image: linear-gradient(180deg, #4ee2ff 0%, #358fff 100%);

      &:hover {
        background-image: linear-gradient(180deg, #358fff 0%, #4ee2ff 100%);
      }

      &:active {
        box-shadow: 0px 2px 4px rgba(67, 28, 0, 0.4);
      }
    }

    .back_divimg {
      background-image: url("@/assets/imgs/Bc2.png");
      background-size: 100% 100%;
      box-shadow: 4px 4px 0px #6982a3;
    }
  }

  .content_bottom_tips {
    margin-top: 40px;

    p {
      text-align: left;
      color: #d5d7e1;
      font-size: 14px;
      line-height: 29px;
    }
  }

  .content_bottom_callme {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;

    div {
      display: flex;
      align-items: center;
    }

    p {
      color: #d5d7e1;
      font-size: 14px;
      margin-right: 180px;

      span {
        color: rgba(70, 173, 255, 1);
        cursor: pointer;
      }
    }

    .icon {
      color: #d5d7e1;
      font-size: 20px;
      margin-right: 6px;
    }
  }
}
</style>
