<!--
 * @Description: 项目字幕设置
 * @version: 
 * @Author: Tom
 * @Date: 2022-11-14 11:03:25
 * @LastEditors: Tom
 * @LastEditTime: 2024-06-14 11:46:23
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'1100px'"
    :top="'5%'"
    center
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="closeDialog"
  >
    <template v-slot:title>
      <div class="tasknameslotTitle">
        <p class="title_p" v-if="CaptionMode == 1">
          <el-tooltip
            class="name overflowEllipsis"
            effect="dark"
            :content="templateName"
            placement="bottom-start"
            v-show="nameInputModel === false"
          >
            <span class="">{{ templateName }}</span>
          </el-tooltip>
          <svg-icon
            iconClass="Edit"
            className="edit"
            @click="nameClick"
            v-show="nameInputModel === false"
          ></svg-icon>
          <el-input
            type="text"
            :placeholder="$t('dubbing.input_placeholder')"
            v-model="templateName"
            :maxlength="50"
            style="width: 280px"
            v-show="nameInputModel"
            @blur="nameInputModelBlur"
            ref="nameInput"
          >
          </el-input>
        </p>
        <p class="title_p" v-if="CaptionMode == 0">
          <span class="">{{ title }}</span>
        </p>
      </div>
    </template>
    <div class="center">
      <div style="margin-bottom: 12px" v-if="CaptionMode == 0">
        <p style="color: #adb0bc; margin: 6px 0">
          {{ $t("ProjectManagement.projectTemplateName") }}
        </p>
        <el-input
          v-model="templateName"
          style="display: inline"
          maxlength="50"
        ></el-input>
      </div>
      <div class="glo_top">
        <div>
          <p
            :class="Glossariestabs == '0' ? 'setbackground' : ''"
            @click="changeGlossariestabs('0')"
          >
            {{ $t("CaptionSettings.tab1") }}
            <i
              v-if="Glossariestabs == '0'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs == '1' ? 'setbackground' : ''"
            @click="changeGlossariestabs('1')"
          >
            {{ $t("CaptionSettings.tab2") }}
            <i
              v-if="Glossariestabs == '1'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs == '2' ? 'setbackground' : ''"
            @click="changeGlossariestabs('2')"
          >
            {{ $t("CaptionSettings.tab3") }}
            <i
              v-if="Glossariestabs == '2'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs == '3' ? 'setbackground' : ''"
            @click="changeGlossariestabs('3')"
          >
            {{ $t("CaptionSettings.tab4") }}
            <i
              v-if="Glossariestabs == '3'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs == '4' ? 'setbackground' : ''"
            @click="changeGlossariestabs('4')"
          >
            AI Translation
            <i
              v-if="Glossariestabs == '4'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs == '5' ? 'setbackground' : ''"
            @click="changeGlossariestabs('5')"
          >
            <!--            字幕区域-->
            {{ $t("CaptionSettings.tab8") }}
            <i
              v-if="Glossariestabs == '5'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs === '6' ? 'setbackground' : ''"
            @click="changeGlossariestabs('6')"
          >
            <!--      ASR 设置      -->
            {{ $t("CaptionSettings.tab7") }}
            <i
              v-if="Glossariestabs === '6'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs === '7' ? 'setbackground' : ''"
            @click="changeGlossariestabs('7')"
          >
            <!--      客户要求      -->
            {{ $t("CaptionSettings.tab10") }}
            <i
              v-if="Glossariestabs === '7'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
      </div>
      <div class="glo_bom">
        <div v-if="Glossariestabs === '0'">
          <div class="glo_bom_top">
            <!-- 每行字幕最短持续时间 -->
            <div>
              <p>
                {{ $t("CaptionSettings.Minimumdurationofsubtitlesperline") }}
              </p>
              <el-input
                v-model="templateInfo.min_duration"
                style="display: inline"
                maxlength="6"
              ></el-input>
              <span class="glo_bom_top_second">Second(s)</span>
            </div>
            <!-- 每行字最长持续时间 -->
            <div style="margin-left: 25px">
              <p>{{ $t("CaptionSettings.Maximumdurationperlineofwords") }}</p>
              <el-input
                v-model="templateInfo.max_duration"
                maxlength="6"
                style="display: inline"
              ></el-input>
              <span class="glo_bom_top_second">Second(s)</span>
            </div>
            <!-- 句段最大行数 -->
            <div style="margin-left: 25px">
              <p>{{ $t("CaptionSettings.Maxlines") }}</p>
              <el-input
                v-model="templateInfo.line_count"
                maxlength="1"
                style="display: inline"
              ></el-input>
              <!-- <span class="glo_bom_top_second"></span> -->
            </div>
          </div>
          <!-- Minigap Time -->
          <div class="glo_bom_center">
            <p>{{ $t("CaptionSettings.MinigapTime") }}</p>
            <div class="checkboxClass">
              <el-input
                v-model="templateInfo.minigap_time"
                maxlength="7"
              ></el-input>
              <el-checkbox v-model="templateInfo.minigap_type" border
                >{{ $t("CaptionSettings.Allowequalsto0") }}
              </el-checkbox>
            </div>
          </div>
          <div
            class="glo_bom_table tableClass borderTableStyle"
            :class="'tablebtmborder'"
          >
            <p>
              {{ $t("CaptionSettings.Maximumwordslimitperlineofsubtitles") }}
            </p>
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#454758',
                color: '#ffffff',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#454758',
              }"
              :data="templateInfo.langs"
              tooltip-effect="dark"
              style="width: 100%"
              max-height="180px"
              border
            >
              <!--  语种-->
              <el-table-column
                prop="language_name"
                :label="$t('CaptionSettings.languages')"
              >
              </el-table-column>
              <!--最大字数  -->
              <el-table-column :label="$t('CaptionSettings.MaximumWords')">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.char_line"></el-input>
                </template>
              </el-table-column>
              <!--读速  -->
              <el-table-column :label="$t('CaptionSettings.Readrate')">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.char_speed"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="Glossariestabs == '1'" class="Glossariestabs2">
          <p>
            {{ $t("CaptionSettings.Glossariestabs2Title") }}
          </p>
          <!-- <div class="radioClass">
            <el-radio-group v-model="templateInfo.radio">
              <el-radio border label="0">{{
                $t("CaptionSettings.nothing")
              }}</el-radio>
              <el-radio border label="1">{{
                $t("CaptionSettings.ChineseCharacters")
              }}</el-radio>
              <el-radio border label="2">{{
                $t("CaptionSettings.EnglishCharacters")
              }}</el-radio>
            </el-radio-group>
          </div> -->
          <div>
            <!-- <div class="Gloss_btm">
              <el-switch
                v-model="templateInfo.value"
                active-color="#46adff"
                inactive-color="#7d7f8b"
              >
              </el-switch>
              <p>{{ $t("CaptionSettings.CustomForbiddenCharacters") }}</p>
            </div> -->
            <el-input
              type="textarea"
              v-model="templateInfo.shield_str"
              resize="none"
              :placeholder="$t('CaptionSettings.Useregextocustomize')"
            ></el-input>
          </div>
        </div>
        <div v-if="Glossariestabs == '2'" class="Glossariestabs3">
          <div class="Glossariestabs3_input">
            <p>{{ $t("CaptionSettings.tab3") }}</p>
            <el-input v-model="templateInfo.watermark"></el-input>
          </div>
        </div>
        <div v-if="Glossariestabs == '3'" class="Glossariestabs4">
          <div
            class="tabs2 tableClass"
            :class="
              Glossariestabs3TableData.length == 0 ? 'tablebtmborder' : ''
            "
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#454758',
              }"
              ref="Glossariestabs3TableData"
              :data="Glossariestabs3TableData"
              tooltip-effect="dark"
              style="width: 100%"
              max-height="320"
              border
            >
              <el-table-column
                :label="$t('ProjectManagement.Colour')"
                width="69"
                align="center"
              >
                <template slot-scope="scope">
                  <div>
                    <div
                      class="divCloor"
                      :style="{ background: scope.row.color }"
                    ></div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                :label="$t('ProjectManagement.CommentName')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-input
                    class="inputClassdisable"
                    v-model="scope.row.comment_name"
                    v-show="!scope.row.disabled"
                    @input="handleInput(scope.row)"
                  ></el-input>
                  {{ scope.row.disabled ? scope.row.comment_name : "" }}
                </template>
              </el-table-column>

              <!-- 所用流程 -->
              <el-table-column
                show-overflow-tooltip
                :label="$t('ProjectManagement.Processused')"
              >
                <template slot-scope="scope">
                  <div class="checkboxClass">
                    <el-checkbox-group
                      v-model="scope.row.work_flow"
                      :disabled="scope.row.disabled"
                    >
                      <el-checkbox :label="1"
                        >{{ $t("ProjectManagement.STT") }}
                      </el-checkbox>
                      <el-checkbox :label="2"
                        >{{ $t("ProjectManagement.TRA") }}
                      </el-checkbox>
                      <el-checkbox :label="3"
                        >{{ $t("ProjectManagement.REV") }}
                      </el-checkbox>
                      <el-checkbox :label="4"
                        >{{ $t("ProjectManagement.Proof") }}
                      </el-checkbox>
                      <el-checkbox :label="5"
                        >{{ $t("CommonName.statusName12") }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </template>
              </el-table-column>

              <!-- 操作 -->
              <el-table-column
                label-class-name="labelClassName"
                width="88"
                align="center"
              >
                <template slot-scope="scope">
                  <div v-show="scope.row.other != 1">
                    <el-button
                      type="text"
                      @click="cancelClick(scope.$index, scope.row)"
                      v-if="scope.row.showbtn"
                    >
                      <div class="svgbtn">
                        <svg-icon icon-class="delete" class="icon"></svg-icon>
                      </div>
                    </el-button>
                    <!-- 确认 -->
                    <el-button
                      type="text"
                      @click="confirmClick(scope.$index)"
                      v-if="scope.row.showEdit"
                    >
                      <div class="svgbtn">
                        <svg-icon icon-class="Edit" class="icon"></svg-icon>
                      </div>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="tableappend">
              <el-button type="text" @click="addtableData">
                +{{ $t("ProjectManagement.AddNewComment") }}
              </el-button>
            </div>
          </div>
        </div>
        <div v-if="Glossariestabs == '4'" class="Glossariestabs5">
          <div class="Glossariestabs5_div">
            <p>{{ "Prompt" }}</p>
            <el-button
              type="text"
              class="Glossariestabs5_button"
              @click="promot_button_click"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('CaptionSettings.srtText6')"
                placement="top"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-button>
          </div>
          <div>
            <el-input
              type="textarea"
              v-model="templateInfo.gpt_promot"
              resize="none"
            ></el-input>
          </div>
        </div>
        <div v-if="Glossariestabs == '5'" class="Glossariestabs6">
          <div>
            <p>{{ $t("CaptionSettings.srtText2") }} (%)</p>
            <el-input
              v-model="templateInfo.left_range"
              @input="Glossariestab6handleInput(1)"
            ></el-input>
          </div>
          <div>
            <p>{{ $t("CaptionSettings.srtText3") }} (%)</p>
            <el-input
              v-model="templateInfo.right_range"
              @input="Glossariestab6handleInput(2)"
            ></el-input>
          </div>
          <div>
            <p>{{ $t("CaptionSettings.srtText4") }} (%)</p>
            <el-input
              v-model="templateInfo.front_range"
              @input="Glossariestab6handleInput(3)"
            ></el-input>
          </div>
          <div>
            <p>{{ $t("CaptionSettings.srtText5") }} (%)</p>
            <el-input
              v-model="templateInfo.rear_range"
              @input="Glossariestab6handleInput(4)"
            ></el-input>
          </div>
        </div>
        <div v-if="Glossariestabs == '6'" class="Glossariestabs7 switch_div">
          <!--          asr 设置-->
          <div class="switch_div_div">
            <p style="color: #ffffff; margin-bottom: 10px">
              <!--              开启/关闭ASR选项，开启后请在列表内选择需要的ASR语言-->
              {{ $t("CaptionSettings.asrText") }}
            </p>
            <el-switch
              v-model="Glossariestab7.switchValue"
              active-color="#46adff"
              inactive-color="#7d7f8b"
            >
            </el-switch>
          </div>
          <div class="select_div">
            <el-select
              v-model="Glossariestab7.selectValue"
              multiple
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              :disabled="Glossariestab7.switchValue == 0"
            >
              <el-option
                v-for="item in selectList"
                :key="item.language_id"
                :label="item.cname"
                :value="item.language_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-if="Glossariestabs == '7'" class="Glossariestabs2">
          <div class="Glossariestabs3_input">
            <!--            客户要求-->
            <p></p>
            <el-input
              type="textarea"
              resize="none"
              maxlength="500"
              v-model="templateInfo.comments"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div>
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button
          type="primary"
          @click="determineClick(0)"
          class="setpadding23"
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Sure") }}
          </p>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  get_caption_info,
  set_project_caption_info,
  get_shield_info,
  set_project_shield_info,
  get_watermark_info,
  get_project_comments,
  set_project_comments,
  update_watermark_batch,
  set_project_gpt_info,
  get_project_gpt_info,
  get_project_area_setting,
  update_project_area_setting,
  get_language,
  set_asr_lang,
  get_project_srt_info,
  set_project_srt_info,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import { setColor, getColor, initColor, colorListLength } from "./color";

export default {
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    projectTemplateInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //0：创建；1：修改
    CaptionMode: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("info"));
  },
  computed: {
    setGlossariestabsleft() {
      let a = "";
      let b = 0;
      if (this.Glossariestabs == "0") {
        b = document.getElementsByClassName("glo_top_p0");

        a = "tabsbtnicon1";
      } else if (this.Glossariestabs == "1") {
        b = document.getElementsByClassName("glo_top_p1");
        a = "tabsbtnicon2";
      } else {
        b = document.getElementsByClassName("glo_top_p2");
        a = "tabsbtnicon3";
      }
      // console.log(b);
      return a;
    },
  },
  data() {
    return {
      info: {},
      dialogVisible: false,
      project_id: "",
      title: "",
      Glossariestabs: "0",
      Glossariestab2: {
        //获取多媒体任务禁止字符信息
        radio: "0",
        value: false,
        textarea: "",
      },
      Glossariestab5: {
        //  GTP 设置
        textarea: "",
        input: 0,
      },
      // 字幕区域
      Glossariestab6: {
        leftInput: 0,
        rightInput: 0,
        topInput: 0,
        bottomInput: 0,
      },
      // asr
      Glossariestab7: {
        switchValue: 0,
        selectValue: [],
      },
      Glossariestab8: {
        input: "",
      },
      // asr 语言列表
      selectList: [],
      tableData: [], //获取多媒体任务字幕规则信息 表格数据
      tabs3DataList: [
        {
          id: "1",
          input1: "42",
          input2: "",
          selectList: ["0", "1"],
        },
        {
          id: "2",
          input1: "水印2",
          input2: "",
          selectList: ["0", "1"],
        },
      ],
      options: [
        {
          id: "1",
          name: "111",
        },
      ],
      MultipleChoiceoptions: [
        {
          value: "0",
          label: "Bleach_EP331_EN.mp4",
        },
        {
          value: "1",
          label: "Bleach_EP332_EN.mp4",
        },
      ],
      //Glossariestab3
      Glossariestab3: {
        WaterprintSTT: "",
        WaterprintTRA: "",
        WaterprintREV: "",
        WaterprintSTQ: "",
        // job_ids: [],
        tab3tableData: [],
      },
      multipleSelection: [],

      // Comment Settings 表格 数据
      Glossariestabs3TableData: [],
      colorIndex: 1,
      errorIndex: 1,
      templateName: "",
      templateNameBase: "", //模板名称
      nameInputModel: false,
      templateInfo: {}, //模板数据信息
    };
  },
  mounted() {
    this.dialogVisible = this.showdialog;
    this.templateInfo = this.projectTemplateInfo;
    initColor();
    if (this.CaptionMode == 0) {
      this.title = this.$t("ProjectManagement.createProjectTemplate");
    } else {
      this.templateNameBase = this.templateInfo.template_name;
      this.templateName = this.templateInfo.template_name;
    }
    if (this.templateInfo.comment_error.length > 0) {
      this.templateInfo.comment_error.forEach((i) => {
        i.showEdit = true;
        i.disabled = true;
        i.showbtn = true;
        getColor(i);
      });
    }
    this.templateInfo.minigap_type =
      this.templateInfo.minigap_type == "1" ? true : false;
    this.Glossariestabs3TableData = this.templateInfo.comment_error;
    this.get_language();
    this.Glossariestab7.switchValue =
      Number(this.templateInfo.asr_lang_mode) === 0 ? false : true;
    this.Glossariestab7.selectValue =
      this.templateInfo.asr_lang == 0
        ? []
        : this.templateInfo.asr_lang.split(",");
    //排序
    // this.templateInfo.langs.sort((a, b) =>
    //   a.language_name.localeCompare(b.language_name)
    // );
  },
  methods: {
    nameClick() {
      this.nameInputModel = true;
      this.templateName = this.templateName.trim();
      this.$nextTick(() => {
        this.$refs.nameInput.focus();
      });
    },
    nameInputModelBlur() {
      this.nameInputModel = false;
      this.templateName =
        this.templateName.trim() !== ""
          ? this.templateName.trim()
          : this.templateNameBase;
    },
    // ==================================接口调用方法==============================
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.selectList = res.list;
          hideLoading();
        }
      });
    },
    /**
     *
     * @param {*创建/修改模板规则信息}
     * project_template_id 为空 则创建，有值则修改
     */
    set_project_template_info() {
      let data = this.templateInfo;
      data["action"] = "create_project_template";
      data["template_name"] = this.templateName.trim();
      data["minigap_type"] = this.templateInfo.minigap_type ? "1" : "0";
      data["asr_lang_mode"] = !this.Glossariestab7.switchValue ? 0 : 1; //0 不开启；1：开启自定义asr语言对
      data["asr_lang"] = !this.Glossariestab7.switchValue
        ? "0"
        : this.Glossariestab7.selectValue.length === 0
        ? "0"
        : this.Glossariestab7.selectValue.join(","); //语言对id集合
      data["project_template_id"] =
        this.CaptionMode == 0 ? "0" : this.templateInfo.project_template_id;
      data["comments"] = data["comments"].trim();
      // console.log(data, "data");
      // return false;
      showLoading(this.$t("CommonName.loadingmessage"));
      set_project_caption_info(data).then((res) => {
        if (!res) {
          return;
        }
        this.closeDialog(1);
        hideLoading();
      });
    },
    /**
     *
     * @param {*修改多媒体任务禁止字符信息} obj
     */
    set_project_shield_info(obj, mode) {
      set_project_shield_info({
        action: "set_shield_info",
        project_id: this.project_id,
        shield_mode: obj.radio,
        customize_mode: obj.value ? 1 : 0,
        shield_str: obj.textarea,
      }).then((res) => {
        if (!res) {
          return;
        }
        if (mode == 1) {
          this.dialogVisible = false;
        }
        this.$emit("clickCaptionSettings", this.dialogVisible);
        hideLoading();
      });
    },
    /**
     *
     * @param {*获取任务水印信息} id
     */
    get_watermark_info(id) {
      return get_watermark_info({
        action: "get_watermark_info",
        project_id: id,
        job_id: "",
      }).then((res) => {
        if (!res) {
          return;
        }
        // this.Glossariestab3 = {
        //   WaterprintSTT: "",
        //   WaterprintTRA: "",
        //   WaterprintREV: "",
        //   WaterprintSTQ: "",
        //   job_ids: [],
        //   tab3tableData: res.list,
        // };
        this.Glossariestab3.tab3tableData = res.list;
        // console.log(res.list);
        hideLoading();
      });
    },
    /**
     *
     * @param {* 更新/添加任务水印信息（批量）} id
     */
    update_watermark_batch(id, obj, mode) {
      // console.log(obj);
      update_watermark_batch({
        action: "update_watermark_batch",
        project_id: id,
        job_ids: obj.job_ids,
        watermark_stt: obj.WaterprintSTT,
        watermark_tra: obj.WaterprintTRA,
        watermark_rev: obj.WaterprintREV,
        watermark_stq: obj.WaterprintSTQ,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_watermark_info(id).then(() => {
          if (mode == 1) {
            this.dialogVisible = false;
          }
        });
      });
    },
    /**
     *
     * @param {*查看项目Comments备注信息} id
     */
    get_project_comments(id) {
      return get_project_comments({
        action: "get_project_comments",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        initColor(); //重置 颜色
        if (res.list.length > 0) {
          res.list.forEach((i) => {
            i.showEdit = true;
            i.disabled = true;
            i.showbtn = true;
            getColor(i);
          });
        }
        // this.errorIndex = 1;
        this.colorIndex = 1;
        this.Glossariestabs3TableData = res.list;
        hideLoading();
      });
    },
    /**
     * 查看GPT设置信息
     * @param id
     */
    get_project_gpt_info(id) {
      get_project_gpt_info({
        action: "get_project_gpt_info",
        project_id: id,
      })
        .then((res) => {
          if (res) {
            this.Glossariestab5.input = res.info.segment_count;
            this.Glossariestab5.textarea = res.info.promot;
          }
        })
        .catch((err) => {
          this.$messages("error", err);
        });
    },
    /**
     * 更新GPT设置信息
     * @param id
     */
    set_project_gpt_info(id, mode) {
      // console.log(this.Glossariestab5);
      set_project_gpt_info({
        action: "set_project_gpt_info",
        project_id: id,
        promot: this.Glossariestab5.textarea,
        segment_count: this.Glossariestab5.input,
      })
        .then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          if (mode == 1) {
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },
    /**
     * 获取视频播放区域设置相关信息
     * @param id
     */
    get_project_area_setting(id) {
      get_project_area_setting({
        action: "get_project_area_setting",
        project_id: id,
      })
        .then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.Glossariestab6.leftInput = res.info.left_range;
          this.Glossariestab6.rightInput = res.info.right_range;
          this.Glossariestab6.topInput = res.info.front_range;
          this.Glossariestab6.bottomInput = res.info.rear_range;
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },
    /**
     * 获取项目SRT设置信息
     * @param id
     */
    get_project_srt_info(id) {
      get_project_srt_info({
        action: "get_project_srt_info",
        project_id: id,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.Glossariestab8.srt_after = res.info.srt_after;
        this.Glossariestab8.srt_before = res.info.srt_before;
        this.Glossariestab8.single = res.info.single == 1 ? true : false;
        this.Glossariestab8.bilingual = res.info.bilingual == 1 ? true : false;
        this.Glossariestab8.source_tag = res.info.source_tag;
        this.Glossariestab8.source_tag_end = res.info.source_tag_end;
        this.Glossariestab8.trans_tag = res.info.trans_tag;
        this.Glossariestab8.trans_tag_end = res.info.trans_tag_end;
        hideLoading();
      });
    },
    /**
     * 修改项目SRT设置信息
     * @param id
     */
    set_project_srt_info(id, mode) {
      set_project_srt_info({
        action: "set_project_srt_info",
        project_id: id,
        srt_before: this.Glossariestab8.srt_before,
        srt_after: this.Glossariestab8.srt_after,
        single: this.Glossariestab8.single ? 1 : 0,
        bilingual: this.Glossariestab8.bilingual ? 1 : 0,
        source_tag: this.Glossariestab8.source_tag,
        source_tag_end: this.Glossariestab8.source_tag_end,
        trans_tag: this.Glossariestab8.trans_tag,
        trans_tag_end: this.Glossariestab8.trans_tag_end,
      })
        .then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          if (mode == 1) {
            this.dialogVisible = false;
          }
          hideLoading();
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },
    /**
     * 修改视频播放区域设置相关信息
     * @param id
     */
    update_project_area_setting(id, mode) {
      update_project_area_setting({
        action: "update_project_area_setting",
        project_id: id,
        left_range: this.Glossariestab6.leftInput,
        right_range: this.Glossariestab6.rightInput,
        front_range: this.Glossariestab6.topInput,
        rear_range: this.Glossariestab6.bottomInput,
      })
        .then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          if (mode == 1) {
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },

    // ==================================通用方法==============================
    /**
     * 关闭弹窗
     */
    closeDialog(data = 0) {
      this.dialogVisible = false;
      this.$emit("clickCaptionSettings", this.dialogVisible, data);
    },
    /**
     *
     * @param {* 确定按钮} data
     * @param {* 0 不关闭； 1 关闭} mode
     */
    determineClick(mode) {
      if (this.templateName.trim() == "") {
        this.$messages(
          "warning",
          this.$t("ProjectManagement.warningmessage12")
        );
        return;
      }
      if (this.Glossariestabs == "0") {
        if (
          !this.templateInfo.min_duration ||
          !this.templateInfo.max_duration ||
          !this.templateInfo.minigap_time ||
          !this.templateInfo.line_count
        ) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage3")
          );
          return;
        }
        for (let index = 0; index < this.templateInfo.langs.length; index++) {
          if (
            !this.templateInfo.langs[index].char_line ||
            !this.templateInfo.langs[index].char_speed
          ) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage3")
            );
            return;
          }
        }
      }
      if (this.Glossariestabs == "3") {
        for (
          let index = 0;
          index < this.Glossariestabs3TableData.length;
          index++
        ) {
          if (!this.Glossariestabs3TableData[index].comment_name) {
            this.$messages(
              "warning",
              this.$t("ProjectManagement.warningmessage10")
            );
            return;
          }
        }
        // 判断是否 重名
        const newArr = this.Glossariestabs3TableData.map(
          (item) => item.comment_name
        );
        const isRepeat = newArr.some(
          (item, index, arr) => arr.indexOf(item) != index
        );
        if (isRepeat) {
          this.$messages(
            "warning",
            this.$t("ProjectManagement.warningmessage11")
          );
          return;
        }
      }
      if (this.Glossariestabs == "5") {
        if (
          this.templateInfo.left_range == "" ||
          this.templateInfo.right_range == "" ||
          this.templateInfo.front_range == "" ||
          this.templateInfo.rear_range == ""
        ) {
          this.$messages(
            "warning",
            this.$t("ProjectManagement.warningmessage8")
          );
          return;
        }
      }
      if (this.Glossariestabs == "6") {
        if (
          this.Glossariestab7.switchValue &&
          this.Glossariestab7.selectValue.length === 0
        ) {
          this.$messages(
            "warning",
            this.$t("ProjectManagement.warningmessage9")
          );
          return;
        }
      }
      this.set_project_template_info();
    },
    // ==================================其他方法====================================

    changeGlossariestabs(str) {
      this.Glossariestabs = str;

      switch (str) {
        case "6":
          // asr
          // showLoading(this.$t("CommonName.loadingmessage"));
          // this.get_language();
          // this.Glossariestab7.switchValue =
          //   Number(this.templateInfo.asr_lang_mode) === 0 ? false : true;
          // this.Glossariestab7.selectValue =
          //   this.templateInfo.asr_lang == 0
          //     ? []
          //     : this.templateInfo.asr_lang.split(",");
          break;
        default:
          break;
      }
    },
    /**
     *
     * @param {* 表格添加} data
     */
    addtableData() {
      this.Glossariestabs3TableData.push({
        comment_id: "",
        color: setColor(this.colorIndex),
        comment_name: "Error " + this.errorIndex,
        work_flow: [1, 2, 3, 4, 5],
        showbtn: true,
        showEdit: false,
        disabled: false,
      });
      if (this.colorIndex == colorListLength()) {
        initColor();
        this.colorIndex = 1;
      } else {
        this.colorIndex++;
      }
      this.errorIndex++;
      this.$nextTick(() => {
        this.$refs.Glossariestabs3TableData.bodyWrapper.scrollTop =
          this.$refs.Glossariestabs3TableData.bodyWrapper.scrollHeight;
      });
    },
    /**
     *
     * @param {取消按钮,移除按钮} row
     */
    cancelClick(index, i) {
      this.Glossariestabs3TableData.splice(index, 1);
    },
    /**
     *
     * @param {*去除首尾空格} row
     */
    handleInput(row) {
      // console.log(row);
      row.comment_name = row.comment_name.trim(); // 去除首尾空格
    },
    /**
     * promot 点击添加 到 promot 输入框中
     * @param data
     */
    promot_button_click() {
      this.templateInfo.gpt_promot +=
        "我想请你作为字幕译员帮从**翻译到**。翻译后目标译文务必加上正确的标点符号，不要遗漏标点特别注意逗号后面首字母要小写（即便换行也要小写），不要漏翻句子";
    },
    /**
     *
     * @param val
     * @constructor
     */
    Glossariestab6handleInput(val) {
      // console.log(val);
      if (val == 1) {
        this.templateInfo.left_range = this.templateInfo.left_range.replace(
          /[^\d]/g,
          ""
        );
        if (parseInt(this.templateInfo.left_range) > 100) {
          this.templateInfo.left_range = 100;
        }
      } else if (val == 2) {
        this.templateInfo.right_range = this.templateInfo.right_range.replace(
          /[^\d]/g,
          ""
        );
        if (parseInt(this.templateInfo.right_range) > 100) {
          this.templateInfo.right_range = 100;
        }
      } else if (val == 3) {
        this.templateInfo.front_range = this.templateInfo.front_range.replace(
          /[^\d]/g,
          ""
        );
        if (parseInt(this.templateInfo.front_range) > 100) {
          this.templateInfo.front_range = 100;
        }
      } else {
        this.templateInfo.rear_range = this.templateInfo.rear_range.replace(
          /[^\d]/g,
          ""
        );
        if (parseInt(this.templateInfo.rear_range) > 100) {
          this.templateInfo.rear_range = 100;
        }
      }
    },
    /**
     *
     * @param {*点击显示 可修改 名称} i
     */
    confirmClick(index) {
      this.Glossariestabs3TableData[index]["disabled"] = false;
      this.Glossariestabs3TableData[index]["showEdit"] = false;
      this.$forceUpdate();
    },
    /**
     * 多选
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialogStyle.scss";

.center {
  margin: 16px 24px 24px;
  ::v-deep .el-checkbox {
    color: #ffffff;
    font-weight: normal;
  }
  .glo_top {
    //width: 840px;
    height: 32px;
    display: flex;
    line-height: 32px;
    background-color: #51546a;
    border-radius: 3px;
    color: #b3d3ec;
    font-size: 14px;

    p {
      padding: 0 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .setbackground {
      background-color: #46adff;
      border-radius: 3px;
      font-weight: 700;
      color: #ffffff;
    }

    .tabsbtnicon {
      position: absolute;
      color: #46adff;
      z-index: 99;
      top: 27px;
    }
  }

  .textareaclass {
    ::v-deep .el-textarea__inner {
      margin-top: 6px;
      margin-bottom: 32px;
      //width: 846px !important;
      height: 138px !important;
      border: 1px solid !important;
      border-color: #7d7f8b !important;
      border-radius: 2px !important;
      padding: 11px 9px !important;
      overflow-y: auto !important;

      &:focus {
        background: none !important;
        color: #ffffff !important;
      }
    }
  }

  .glo_bom {
    .glo_bom_top {
      display: flex;

      ::v-deep .el-input__inner {
        width: 200px;
      }

      p {
        margin: 24px 0 6px 0;
        color: #adb0bc;
        font-size: 14px;
      }

      .glo_bom_top_second {
        color: #adb0bc;
        font-size: 14px;
        margin-left: 5px;
      }
    }

    .glo_bom_center {
      ::v-deep .el-input__inner {
        width: 200px;
      }

      p {
        margin: 16px 0 6px 0;
        color: #adb0bc;
        font-size: 14px;
      }

      .checkboxClass {
        margin-top: 0;

        .el-input {
          width: auto;
        }

        .el-checkbox.is-bordered {
          width: 200px;
          border-left: none;
        }

        ::v-deep .el-checkbox.is-bordered.is-checked {
          border-color: #7d7f8b !important;
        }

        // display: flex;
      }
    }

    .glo_bom_table {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin: 16px 0 6px 0;
      }

      ::v-deep .el-input__inner {
        width: 258px;
      }

      ::v-deep .el-table__header colgroup col[name="gutter"] {
        display: table-cell !important;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      ::v-deep .el-table {
        th.gutter,
        colgroup.gutter {
          background: #454758; //此处的宽度值，对应你自定义滚动条的宽度即可
        }
      }
      .btn1 {
        font-size: 16px;
      }
    }

    .Glossariestabs2 {
      p {
        font-size: 14px;
        color: #adb0bc;
        margin-top: 18px;
      }

      .radioClass {
        margin: 6px 0 27px 0;

        .el-radio.is-bordered {
          width: 180px;
        }
      }

      .Gloss_btm {
        display: flex;

        p {
          color: #ffffff;
          margin-top: 0;
          margin-left: 8px;
        }

        ::v-deep .el-switch__core {
          width: 48px;
          height: 24px;
        }

        ::v-deep .el-switch__core:after {
          width: 18px;
          height: 18px;
          top: 2px;
          background-color: #393c4e;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
        }
      }

      @extend .textareaclass;
    }

    .Glossariestabs3 {
      margin-top: 8px;

      .Glossariestabs3_input {
        ::v-deep .el-input__inner {
          width: 950px !important;
        }
      }

      p {
        margin: 16px 0 6px 0;
        color: #adb0bc;
        font-size: 14px;
      }

      .inputtop {
        display: flex;

        ::v-deep .el-input__inner {
          width: 400px;
        }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }

      .selectbtm {
        // ::v-deep .el-input__inner {
        //width: 780px;
        // }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }

        ::v-deep .el-tag.el-tag--info {
          background-color: #5d5e6e;
          border: none;
          color: #ffffff;
        }

        ::v-deep .el-tag.el-tag--info .el-tag__close {
          color: #a9b5c9;
          font-size: 13px;
        }

        ::v-deep .el-select .el-tag__close.el-icon-close {
          background: none;
        }
      }

      .borderDiv {
        margin-top: 20px;
        width: 840px;
        height: 1px;
        background-image: linear-gradient(
          to right,
          #757784 0%,
          #757784 50%,
          transparent 50%
        );
        background-size: 18px 1px;
        background-repeat: repeat-x;
      }

      .el-button {
        padding: 0;
        margin-top: 20px;
      }
    }

    .Glossariestabs4 {
      .tabs2 {
        ::v-deep .el-input.is-disabled .el-input__inner {
          border: none !important;
          background: none !important;
        }

        .divCloor {
          width: 14px;
          height: 14px;
          border-radius: 100%;
          margin: 0 auto;
        }

        ::v-deep .el-input__inner {
          width: 100% !important;
        }

        .labelClassName {
          .el-button--text {
            padding: 0 !important;
          }
        }

        .tableappend {
          height: 40px;
          background-color: #393c4e;
          line-height: 40px;
          padding-left: 12px;
          border-left: 1px solid #515464;
          border-bottom: 1px solid #515464;
          border-right: 1px solid #515464;
        }

        ::v-deep .el-table {
          overflow: initial;
        }

        .checkboxClass .el-checkbox-group {
          width: 100%;
          justify-content: space-between;
        }

        .checkboxClass {
          margin-top: 0px;
        }

        ::v-deep .el-checkbox__label {
          line-height: 1;
        }
      }
    }

    .Glossariestabs5 {
      @extend .pclass;

      .Glossariestabs5_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;

        p {
          margin-top: 0;
        }
      }

      .Glossariestabs5_button {
        padding: 0 !important;

        i {
          font-size: 16px;
        }
      }

      ::v-deep .el-input-number {
        width: auto !important;

        .el-input__inner {
          text-align: left !important;
        }
      }

      ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
        border-bottom: none !important;
      }

      ::v-deep .el-input-number__increase {
        border-left: none !important;
      }

      ::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
        border-left: none !important;
      }

      ::v-deep .el-input-number__decrease,
      ::v-deep .el-input-number__increase {
        background: none !important;
        color: #adb0bc !important;
        width: auto !important;
        margin-right: 10px !important;
        font-size: 16px !important;
      }

      ::v-deep .el-input-number.is-controls-right .el-input-number__decrease,
      ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
        line-height: 16px !important;
      }

      ::v-deep .el-input-number__increase {
        top: 4px !important;
      }

      ::v-deep .el-input-number__decrease {
        bottom: 4px !important;
      }

      @extend .textareaclass;
    }

    .Glossariestabs6 {
      display: flex;
      justify-content: space-around;
      @extend .pclass;

      ::v-deep .el-input__inner {
        margin-top: 6px;
        width: 180px !important;
      }
    }

    .Glossariestabs7 {
      .switch_div_div {
        margin: 24px 0 24px 0;
      }

      .select_div {
        width: 100%;

        .el-select {
          width: 100%;
        }

        ::v-deep .el-input__inner {
          width: 100% !important;
        }
      }
    }
  }
}

.switch_div {
  ::v-deep .el-switch__core {
    width: 48px;
    height: 24px;
  }

  ::v-deep .el-switch__core:after {
    width: 18px;
    height: 18px;
    top: 2px;
    background-color: #393c4e;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }
}

.pclass {
  p {
    font-size: 14px;
    color: #adb0bc;
    margin-top: 18px;
  }
}

.tableClass {
  margin-top: 21px;
}
.tasknameslotTitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title_p {
    width: 400px;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
  }

  p {
    margin-left: 24px;
  }
  .name {
    max-width: 300px;
  }
  .edit {
    margin-left: 8px;
    cursor: pointer;
  }
  .tasktitlebtn {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .svgbtn {
      margin-right: 14px;
    }
  }

  .lastdiv {
    width: 1px;
    height: 12px;
    background-color: #757784;
    margin: 0 24px 0 10px;
  }
}
</style>
